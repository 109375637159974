/* TABLE OF CONTENTS
 *
 * 1.0 RESET & BASE STYLES
 * 2.0 TYPOGRAPHY
 * 3.0 LINK STYLES AND BUTTONS
 * 4.0 FORMS
 * 5.0 GRID STRUCTURE & LAYOUT COMPONENTS
 * 6.0 CUSTOM MODULES 
 * 7.0 MEDIA QUERIES
 * 8.0 COLOUR THEMES
 * 9.0 CSS3 ANIMATIONS
 * 10.0 PRINT STYLES
 * 11.0 FOUC
 * 12.0 Z-INDEX REFERENCE
 * 
 * */



/* 1.0 RESET STYLES (HTML5 ✰ Boilerplate & Normalise reset, & Twitter Bootstrap typography ) --------------------------------------------------------*/
html { overflow-y: scroll}/* always force a scrollbar in non-IE */

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { display: block; }
audio, canvas, video { display: inline-block; }
.lt-ie8 audio, .lt-ie8 canvas, .lt-ie8 video {display: inline; zoom: 1;}
audio:not([controls]) { display: none; }
[hidden] { display: none; }

/* Lists */
ul{padding: 0;margin: 0 0 9px 15px;}
ul ul,ul ol,ol ol,ol ul {margin-bottom: 0;}

ul {list-style: square;}
ol{margin-left:-20px}
.lt-ie8 ol { padding: 0;margin: 0 0 9px 25px; }
li {line-height: 18px;display:list-item}

ol.campl-numbered { counter-reset: item;padding: 0;margin: 0 0 9px 0;list-style: decimal; }
ol.campl-numbered li { display: block; }

ol.campl-numbered li:before {
content: counter(item) " ";
counter-increment: item;
font-weight: bold;
padding-right:5px
}

.campl-unstyled-list{list-style:none;margin:0}
.campl-menu-list{margin-bottom:9px}
.campl-menu-list li{padding:10px;border-bottom:1px solid #999}

dl {margin-bottom: 18px;}
dt,dd {line-height: 18px;}
dt {font-weight: bold;line-height: 17px;}
dd {margin-left: 9px;}

/* blockquotes */
blockquote {padding: 0 0 0 25px;margin: 18px 0;font-style:italic;background:url(../assets/bg-blockquote-top-768ac2891f28a2a4e66d2875d9587fdc.png) no-repeat top left}
blockquote p {margin-bottom: 10px;font-weight: 300;line-height: 22.5px;float:left;padding-right:25px}
blockquote p.campl-quote-mark{background:url(../assets/bg-blockquote-bottom-61b1cfe35bf6c13471ace1b8027afff1.png) no-repeat bottom right;}
blockquote cite {display: block;line-height: 18px;color: #999999;clear:both}
blockquote cite:before {content: '\2014   \A0';}
q:before,q:after,blockquote:before,blockquote:after {content: ""; content: none;}
blockquote.campl-float-right {margin-left:20px;width:230px;margin-top:0px;}
blockquote.campl-float-right p {padding-right:0px;background:url(../assets/bg-blockquote-bottom-61b1cfe35bf6c13471ace1b8027afff1.png) no-repeat bottom right;}

/* Misc */
hr {margin: 5px 0 20px;border: 0;border-top: 1px solid #eeeeee;border-bottom: 1px solid #ffffff;height:1px}
b, strong {font-weight: bold;}
em {font-style: italic;}
address {display: block;margin-bottom: 18px;font-style: normal;line-height: 18px;}
small {font-size: 85%;}
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }
dfn { font-style: italic;font-weight:normal}
ins { background: #ff9; color: #000; text-decoration: none; }
mark { background: #ff0; color: #000; font-style: italic; font-weight: bold; }

abbr[title] {cursor: help;border-bottom: 1px dotted #999999;}
abbr.initialism {font-size: 90%;text-transform: uppercase;}

code, kbd, samp, pre {padding: 0 3px 2px;font-family: "Courier New", monospace;font-size: 12px;color: #333333;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;}
code {padding: 2px 4px;color: #d14;background-color: #f7f7f9;border: 1px solid #e1e1e8;}
pre {display: block;padding: 8.5px;margin: 0 0 9px;font-size: 12.025px;line-height: 18px;word-break: break-all;word-wrap: break-word;white-space: pre;
	white-space: pre-wrap;background-color: #f5f5f5;border: 1px solid #ccc;border: 1px solid rgba(0, 0, 0, 0.15);-webkit-border-radius: 4px;-moz-border-radius: 4px;
  	border-radius: 4px}

/* images */
img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }

.campl-scale-with-grid {max-width: 100%;height: auto; }
img.campl-float-right{float:right;margin:10px 0 10px 20px;clear:both}
img.campl-float-left{float:left;margin:10px 20px 10px 0;clear:both}


svg:not(:root) { overflow: hidden; }
figure { margin: 0; }

/* re-usable css objects */
.chromeframe { margin: 0.2em 0; background: #ccc; color: black; padding: 0.2em 0; }

.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }
.lt-ie8 .ir{line-height: 0;}
.ir br { display: none; }

.hidden { display: none !important; visibility: hidden; }
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }

.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.lt-ie8 .clearfix {zoom: 1; }

.campl-horizontal-navigation li{float:left}

.campl-float-right{float:right;clear:both}
.campl-float-left{float:left;clear:both}

.campl-break-word {word-wrap: break-word;}

/* 2.0 TYPOGRAPHY ---------------------------------------------------------------------------------------------------*/
html { font-size: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; text-size-adjust: 100%;}/*setting -webkit-text-size-adjust: none prevents users resizing with magnify*/
html, button, input, select, textarea { font-family: verdana, arial, sans-serif; color: #171717; }
h1,h2,h3,h4,h5,h6, .campl-page-title {font-family: inherit;color: inherit;text-rendering: optimizelegibility; margin:0 0 10px 0;vertical-align: baseline;}
.lt-ie8 h1, .lt-ie8 h2, .lt-ie8 h3, .lt-ie8 h4, .lt-ie8 h5, .lt-ie8 h6, .lt-ie8 .campl-page-title{vertical-align: middle}

::-moz-selection { background: #0066ff; color: #fff; text-shadow: none; }
::selection { background: #0066ff; color: #fff; text-shadow: none; }

p { margin:0 0 10px 0}

/* Font families */

/* Bold		"myriad-pro"	700	normal */
.campl-highlight-day, .campl-promo-teaser .campl-teaser-title, .campl-current-date{font-family:"myriad-pro-n7", "myriad-pro", myriad, verdana, arial, sans-serif;font-weight:700;font-style:normal}

/* Semibold	"myriad-pro"	600	normal */
h2, h3, h4, h5, h6, .campl-global-navigation, .campl-global-navigation-header-container, .campl-global-navigation-container, .campl-local-footer h3, .campl-global-footer h3, 
.campl-teaser-title, .campl-listing-title, .campl-vertical-breadcrumb, .campl-btn{font-family:"myriad-pro-n6", "myriad-pro", myriad, verdana, arial, sans-serif;font-weight:600;font-style:normal}

/* Regular 	"myriad-pro"  	400 normal*/
.campl-branding-title, .campl-topic, .campl-global-navigation-tertiary, .campl-local-navigation, .campl-vertical-breadcrumb-navigation{font-family:"myriad-pro-n4", "myriad-pro", myriad, verdana, arial, sans-serif;font-weight:400;color:#888888}

/* Regular italic "myriad-pro"  400 italic */
.campl-title-search-term{font-family:"myriad-pro-i4", "myriad-pro", myriad, verdana, arial, sans-serif;font-style: italic;font-weight: 400;}


/* Light	"myriad-pro"	300	normal */
h1, .campl-page-header h1, .campl-page-sub-title h2, .campl-secondary-content h2, .campl-main-content-sub-column h2, .campl-slide-caption-txt, .campl-carousel-content p,
.campl-page-title, .campl-sub-title, legend, .campl-primary-cta, .campl-homepage-content h2, .campl-highlight-date, .campl-light-heading{font-family:"myriad-pro-n3", "myriad-pro", myriad, verdana, arial, sans-serif;font-weight:300;}


/* Regular verdana bold */
.campl-desktop-list-layout, .campl-global-navigation-drawer{font-weight:bold}

.campl-global-navigation-secondary-with-children p{font-weight:normal}

/* Georgia "sans serif" normal */
.campl-datestamp, blockquote{font-family:Georgia, "sans serif"}


/* Font sizes*/
.campl-page-header h1, .campl-carousel-content p, .campl-page-title{font-size: 40px;line-height: 48px;color:#fff;}
.campl-page-sub-title h2, .campl-page-sub-title h1, .campl-sub-title, .campl-highlight-day{font-size: 30px;line-height: 38px; color:#fff;}
.campl-homepage-content h2{font-size: 30px;line-height: 38px; }
h1, .campl-light-heading{font-size: 30px;line-height: 36px;}
h2, .campl-slide-caption-txt,  legend,  .campl-load-more-btn, blockquote{font-size: 19px;line-height: 25px;}
.campl-nav-tabs, .campl-nav-pills {font-size: 19px;margin-bottom: 18px;font-weight: 200;line-height: 25px;}
h3, .campl-current-date, .campl-highlight-date, .campl-promo-teaser .campl-teaser-title{font-size: 17px;line-height: 23px;}
h4, .campl-branding-title{font-size:15px;line-height: 21px;}
h5, .campl-tertiary-navigation, .campl-enlarged-text, .campl-teaser-title,  .campl-primary-cta, .campl-btn, 
.campl-local-footer h3, .campl-global-footer h3, .campl-desktop-list-layout, .campl-global-navigation,.campl-global-navigation-header-container,
.campl-global-navigation-container, .campl-local-navigation {font-size:14px;line-height: 20px;}
h6, cite{font-size:13px;line-height: 19px}

body, label, input, button, select, textarea{font-size: 12px;font-weight: normal;line-height: 18px;vertical-align: baseline; }
.lt-ie8 body, .lt-ie8 label, .lt-ie8 input, .lt-ie8 .campl-highlight, button, .lt-ie8 select, textarea.lt-ie8 {vertical-align: middle}

.campl-pagination{font-size:11px;line-height: 15px}

/* resets myriad heading to be verdana */
.campl-simple-heading, .campl-listing-title{font-family: verdana, arial, sans-serif;font-size:14px;line-height: 20px;font-weight:bold}


/* special text treatments */
.campl-highlight{color:#fff;background:#55a51c;text-transform:uppercase;font-size:11px;font-weight:bold;padding:1px 3px;margin-top:-15px;display:inline-block;margin-bottom:10px}
.campl-highlight-alert{color:#fff;background:#ea7125;text-transform:uppercase;font-size:11px;font-weight:bold;padding:1px 3px;margin-top:-15px;display:inline-block;margin-bottom:10px}
.campl-news-listing .campl-datestamp{color:#777777;background:none;font-weight:normal;font-style:italic;font-size:14px}
.campl-highlight-date{text-transform:uppercase;}
.campl-highlight-day{line-height:30px}
.campl-search-term{background:#fff79f;padding:0 3px;display:inline-block}
.campl-topic{text-transform:uppercase;font-size:11px;background:#fff;color:#171717;padding:0 6px 5px 0;display:inline-block;}

/* 3.0 LINK STYLES AND BUTTONS  (Button styles from twitter bootstrap )--------------------------------------------------------------------------------------*/
a, .campl-vertical-breadcrumb , .campl-vertical-breadcrumb-navigation, .campl-vertical-breadcrumb-children a{ color: #6b2c91; text-decoration:none;border-bottom:0;outline: none;}
a:visited { color: #8a00bc; text-decoration:none;border-bottom:1px dotted #ddbfdc}
a:focus { color: #6b2c91; outline: thin dotted;border-bottom:0 }
a:hover { color: #6b2c91; text-decoration:underline;border-bottom:0 }
a:hover, a:active { outline: none; }
a:active { color: #6b2c91; text-decoration:none;border-bottom:0}

.campl-global-navigation a, .campl-page-header a, .campl-local-footer a, .campl-global-footer a, .campl-global-navigation-drawer a, .campl-quicklinks a,
.campl-open-quicklinks, .campl-open-quicklinks:focus, .campl-open-quicklinks:hover, .campl-open-quicklinks:visited, 
.campl-desktop-list-layout a:focus, .campl-desktop-list-layout a:hover, .campl-desktop-list-layout .campl-selected, 
.campl-section-list-heading a, .campl-section-list-heading a:focus, .campl-section-list-heading a:hover, .campl-focus-teaser a,  .campl-focus-teaser a:focus, .campl-focus-teaser a:hover{color:#fff;text-decoration:none}

.campl-quicklinks-list a:focus, .campl-quicklinks-list a:hover, .campl-quicklinks-list a:active{text-decoration:none;background-color:#171717}

.campl-global-navigation a:focus, .campl-global-navigation a:hover,.campl-global-navigation a:active, .campl-global-navigation .campl-selected a{color:#7a7a7a;text-decoration:none}

.campl-global-navigation a:visited, .campl-desktop-list-layout a:visited, .campl-close-panel:visited, .campl-section-list-heading a:visited,
.campl-homepage-quicklinks a:visited, .campl-slide-caption a, .campl-quicklinks-list a:visited{border-bottom:0}

.campl-global-navigation-drawer a:focus, .campl-global-navigation-drawer a:hover, .campl-global-navigation-drawer a:active{text-decoration:underline}

.campl-btn{background:#e4e4e4;border:1px solid #999;padding:4px 10px 4px 10px;}

.campl-primary-cta{display:inline-block;background:#171717 url(../assets/bg-primary-cta-arrow-b8507c6c9a974e6f2a2c843f1de47a0c.png) 100% 50% no-repeat;color:#fff;padding:5px 25px 5px 10px;border:0;text-shadow: none}
.campl-primary-cta:link, .campl-primary-cta:visited{color:#f8f8f8;background:#171717 url(../assets/bg-primary-cta-arrow-b8507c6c9a974e6f2a2c843f1de47a0c.png) 100% 50% no-repeat;border:0}
.campl-primary-cta:focus, .campl-primary-cta:hover, .campl-primary-cta:active{color:#f8f8f8;background:#454545 url(../assets/bg-primary-cta-arrow-over-e1c2c279fd9f43f47c9ac77d900dfecc.png) 100% 50% no-repeat;border:0;text-decoration:none}

.campl-secondary-cta{background:url(../assets/bg-secondary-cta-arrow-064e2ad2bc8c862876aecb6a754e431d.png) 100% 50% no-repeat; display:inline-block;padding-right:10px }

.campl-search-listing a, .campl-vertical-breadcrumb a{color:#999999}
.campl-search-listing .campl-listing-title a{color: #6b2c91;}
.campl-teaser-title a, .campl-pagination a, .campl-load-more-btn, .campl-load-more-btn:focus, .campl-load-more-btn:hover, .campl-load-more-btn:visited{color:#171717}
.campl-focus-teaser a{color:#fff}

.campl-btn-download-vcard:link,
.campl-btn-download-vcard:visited{background:#171717 url(../assets/icon-profile-e7f0305c26bed738f5ad5bc419210e9a.png) 5px 50%  no-repeat;padding:5px 5px 5px 30px;}
.campl-download-arrow{background:url(../assets/bg-download-arrow-577c029e2d1d24ad107fea901e93f1fc.png) center right no-repeat;height:11px;width:11px;padding-right:20px;/*position:absolute;top:50%;right:5px;margin-top:-5px*/}

.campl-btn-download-vcard:focus, .campl-btn-download-vcard:hover, .campl-btn-download-vcard:active{color:#f8f8f8;background-color:#454545}

/*skip to links for accessibility*/
.campl-skipTo:link{position:absolute;left:-5000px;top:0;color:#fff;padding:10px}
.campl-skipTo:focus{left:5px;top:5px;color:#fff;display:block;position:relative}

a.campl-external{background: url(../assets/icon-external-link-550ad2c0e2482771d112a37dc21ab7da.png) no-repeat 100% 4px;  padding-right:15px;}

/* Load More */
.campl-load-more-btn{background:#f3f3f3;border:1px solid #cdcdcd;margin-bottom:60px;text-align:center;display:block;padding:10px;}
.campl-load-more-btn:link, .campl-load-more-btn:focus, .campl-load-more-btn:hover, .campl-load-more-btn:visited{border:1px solid #cdcdcd;}

/* focus links */
.campl-focus-link{width:33px;height:33px;background:#313131 url(../assets/icon-fwd-btn-6be0e549306bcafcde64ca8eb6e840ac.png) 14px 13px no-repeat;position:absolute;bottom:0;right:0}

/* Inline links */
.campl-icon{display:inline-block;background:url(../assets/icon-links-sprite-a14c5def2eb70c0953049f6be990f484.png) no-repeat 0 0;width:20px;height:25px;margin-right:2px;position:relative;top:5px}

.campl-print-icon{background-position:0 5px}
.campl-lock-icon{background-position: 0 -25px}
.campl-help-icon{background-position: 0 -55px}
.campl-info-icon{background-position: 0 -80px}
.campl-excel-icon{background-position:0 -110px}
.campl-pdf-icon{background-position:  0 -135px}
.campl-word-icon{background-position: 0 -159px}


/* 4.0 FORMS --------------------------------------------------------------------------------------------------------*/

/* Standard form elements */
form { margin: 0; }
fieldset { border: 0; margin: 0; padding: 0; }
label { cursor: pointer;  display: block;margin-bottom: 5px; }
legend { border: 0; padding: 0; white-space: normal;display: block;width: 100%;padding: 0;margin-bottom: 10px; }
.lt-ie8 legend{margin-left: -7px;}

button, input[type="button"], input[type="image"], input[type="reset"], input[type="submit"] { cursor: pointer; -webkit-appearance: button;margin-bottom:5px; }
input[type="image"] { -webkit-appearance: none; }
.lt-ie8 button, .lt-ie8 input{overflow: visible;zoom:1;margin: 0 2.5px 5px 2.5px}
button[disabled], input[disabled] { cursor: default;color:#999 }
button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

:-moz-placeholder {color: #999999;}
:-ms-input-placeholder {color: #999999;}
::-webkit-input-placeholder {color: #999999;}

.campl-uneditable-textarea {height: auto;}
.campl-uneditable-input {overflow: hidden;white-space: nowrap;cursor: not-allowed;background-color: #ffffff;border-color: #eee;}

input:invalid, textarea:invalid { background-color: #f0dddd; }

.campl-input-block-level {display: block;width: 100%;min-height: 28px;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;-ms-box-sizing: border-box;box-sizing: border-box;}
input,textarea {width: 210px;}
.lt-ie7 input { vertical-align: text-bottom; }
input,textarea,.campl-uneditable-input { margin-left: 0;}

.campl-input-mini {width: 60px;}
.campl-input-small {width: 90px;}
.campl-input-medium {width: 150px;}
.campl-input-large {width: 210px;}
.campl-input-xlarge {width: 270px;}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.campl-uneditable-input {display: inline-block;height: 18px;padding: 4px;margin-bottom: 9px; -webkit-border-radius: 0;}

textarea { overflow: auto; vertical-align: top; resize: vertical;height: auto; }

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.campl-uneditable-input {background-color: #ffffff;border: 1px solid #cccccc;}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.campl-uneditable-input:focus {border-color: rgba(82, 168, 236, 0.8);outline: 0;}

.lt-ie8 textarea:focus,
.lt-ie8 input[type="text"]:focus,
.lt-ie8 input[type="password"]:focus,
.lt-ie8 input[type="datetime"]:focus,
.lt-ie8 input[type="datetime-local"]:focus,
.lt-ie8 input[type="date"]:focus,
.lt-ie8 input[type="month"]:focus,
.lt-ie8 input[type="time"]:focus,
.lt-ie8 input[type="week"]:focus,
.lt-ie8 input[type="number"]:focus,
.lt-ie8 input[type="email"]:focus,
.lt-ie8 input[type="url"]:focus,
.lt-ie8 input[type="search"]:focus,
.lt-ie8 input[type="tel"]:focus,
.lt-ie8 input[type="color"]:focus,
.lt-ie8 .campl-uneditable-input:focus { outline: dotted 1px;}


input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {cursor: not-allowed;background-color: #eeeeee;border-color: #ddd;}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {background-color: transparent;}

input[type="submit"],input[type="reset"],input[type="button"],input[type="image"],input[type="radio"],input[type="checkbox"] {width: auto;}

input[type="image"] { display: inline; vertical-align: middle; margin-bottom: 5px; }

/* select menus */
select,
input[type="file"] {
  height: 28px;
  line-height: 28px;
}
/* In IE7, the height of the select element cannot be changed by height, only font-size */
.lt-ie8 select,
.lt-ie8 input[type="file"]{margin-top: 4px;}/* For IE7, add top margin to align select with labels */

select { width: 220px;border: 1px solid #bbb;}
select[multiple],select[size] {height: auto;}
select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {outline: thin dotted #333;outline: 5px auto -webkit-focus-ring-color;outline-offset: -2px;}

/* radio buttons and checkboxes */
input[type="radio"],
input[type="checkbox"] {margin: 3px 0;/line-height: normal;cursor: pointer;box-sizing: border-box; padding: 0; }
.lt-ie8 input[type="checkbox"] { vertical-align: baseline;margin-top: 0;width: 13px;height: 13px; }

.campl-radio,.campl-checkbox {min-height: 18px;padding-left: 18px;}
.campl-radio input[type="radio"],.campl-checkbox input[type="checkbox"] {float: left;margin-left: -18px;}
.campl-controls > .campl-radio:first-child,.campl-controls > .campl-checkbox:first-child {padding-top: 5px;}
.campl-radio.inline,.campl-checkbox.inline {display: inline-block;padding-top: 5px;margin-bottom: 0;vertical-align: middle;}
.campl-radio.inline + .campl-radio.inline,.campl-checkbox.inline + .campl-checkbox.inline {margin-left: 10px;}

/* containers for holding form elements */
.campl-control-group {margin-bottom: 9px;}
legend + .campl-control-group {margin-top: 18px;-webkit-margin-top-collapse: separate;}

.campl-control-group.campl-warning > label,
.campl-control-group.campl-warning .campl-help-block{color: #c09853;}

.campl-control-group.campl-warning .campl-checkbox,
.campl-control-group.campl-warning .campl-radio,
.campl-control-group.campl-warning input,
.campl-control-group.campl-warning select,
.campl-control-group.campl-warning textarea {color: #c09853;border-color: #c09853;}

.campl-control-group.campl-warning .campl-checkbox:focus,
.campl-control-group.campl-warning .campl-radio:focus,
.campl-control-group.campl-warning input:focus,
.campl-control-group.campl-warning select:focus,
.campl-control-group.campl-warning textarea:focus {border-color: #a47e3c;}

.campl-control-group.campl-error > label,
.campl-control-group.campl-error .campl-help-block {color: #b94a48;}

.campl-control-group.campl-error .campl-checkbox,
.campl-control-group.campl-error .campl-radio,
.campl-control-group.campl-error input,
.campl-control-group.campl-error select,
.campl-control-group.campl-error textarea {color: #b94a48;border-color: #b94a48;}

.campl-control-group.campl-error .campl-checkbox:focus,
.campl-control-group.campl-error .campl-radio:focus,
.campl-control-group.campl-error input:focus,
.campl-control-group.campl-error select:focus,
.campl-control-group.campl-error textarea:focus {border-color: #953b39;}

.campl-control-group.campl-success > label,
.campl-control-group.campl-success .campl-help-block {color: #468847;}

.campl-control-group.campl-success .campl-checkbox,
.campl-control-group.campl-success .campl-radio,
.campl-control-group.campl-success input,
.campl-control-group.campl-success select,
.campl-control-group.campl-success textarea {color: #468847;border-color: #468847;}

.campl-control-group.campl-success .campl-checkbox:focus,
.campl-control-group.campl-success .campl-radio:focus,
.campl-control-group.campl-success input:focus,
.campl-control-group.campl-success select:focus,
.campl-control-group.campl-success textarea:focus {border-color: #356635;}

/* form actions for buttons */
.campl-form-actions {padding: 20px 0;margin:20px 0;border-top: 1px solid #e4e4e4;}
.lt-ie8 .campl-form-actions{zoom: 1;}
.campl-form-actions:before,.campl-form-actions:after {display: table;content: "";}
.campl-form-actions:after {clear: both;}

/* guidance along side form elements */
.campl-help-block{color: #555555;display: block;margin-bottom: 9px;}

/* 6.0 GRID STRUCTURE & LAYOUT COMPONENTS (Grid taken from centage fluid grid framework)----------------------------------------------------*/
body{margin:0;padding:0; background:#000;width:100%;}

.campl-row{clear:both;width:100%;}
.lt-ie9 .campl-row, .lt-ie8 .campl-row{min-width:auto}

.campl-wrap{margin:0 auto;position:relative;clear:both;width:1177px}
.campl-fixed-container{width:1024px}/*to remove fluid container from browsers without media queries */

.campl-column, .campl-column1, .campl-column2, .campl-column3, .campl-column4, .campl-column5, .campl-column6, .campl-column7, .campl-column8, .campl-column9,
.campl-column10, .campl-column11, .campl-column12{float: left;margin-right: 0;padding:0 0 0 0;}

.campl-column1 {width: 8.333333333333334%;}
.campl-column2 {width: 16.666666666666668%;}
.campl-column3 {width: 25%;}
.campl-column4 {width: 33.333333333333336%;}
.campl-column5 {width: 41.66666666666667%;}
.campl-column6 {width: 50%;}
.campl-column7 {width: 58.333333333333336%;}
.campl-column8 {width: 66.66666666666667%;}
.campl-column9 {width: 75%;}
.campl-column10 {width: 83.33333333333334%;}
.campl-column11 {width: 91.66666666666667%;}
.campl-column12 {width: 100%;}

/* IE fluid widths */

.lt-ie8 .campl-column1 {width: 8.133333333333335%;}
.lt-ie8 .campl-column2 {width: 16.46666666666667%;}
.lt-ie8 .campl-column3 {width: 24.8%;}
.lt-ie8 .campl-column4 {width: 33.13333333333333%;}
.lt-ie8 .campl-column5 {width: 41.46666666666667%;}
.lt-ie8 .campl-column6 {width: 49.8%;}
.lt-ie8 .campl-column7 {width: 58.13333333333333%; }
.lt-ie8 .campl-column8 {width: 66.46666666666667%; }
.lt-ie8 .campl-column9 {width: 74.8%;}
.lt-ie8 .campl-column10 {width: 83.13333333333334%}
.lt-ie8 .campl-column11 {width: 91.46666666666667%;}
.lt-ie8 .campl-column12 {width: 99.8%;}


/* content containers - structural */
.campl-content-container{padding:20px;}
.campl-search-container{padding:20px 100px;}

.campl-no-top-padding, .campl-global-navigation-container, .campl-navigation-list{padding:0 20px 20px 20px}
.campl-no-bottom-padding{padding:20px 20px 0 20px}
.campl-top-padding{padding:20px 0 0 0 }

.campl-bottom-padding, .campl-vertical-teaser-img{padding:0 0 20px 0 }
.campl-side-padding{padding:0 20px}
.campl-vertical-padding, .campl-listing-txt{padding:20px 0}

.campl-horizontal-teaser-img, .campl-right-padding{padding:0 20px 0 0 }
.campl-horizontal-teaser-txt, .campl-left-padding{padding:0 0 0 20px }

.campl-no-left-padding{padding:20px 20px 20px 0}

.campl-listing-img{padding:10px 0 10px 15px }

.campl-vertical-teaser-txt, .campl-no-padding, .campl-promo-teaser .campl-vertical-teaser-img, .campl-focus-teaser .campl-horizontal-teaser-img, .campl-quicklinks-list{padding:0 0 0 0 }

.campl-promo-teaser .campl-vertical-teaser-txt{padding:15px}
.campl-focus-teaser .campl-horizontal-teaser-txt{padding:15px 15px 25px 15px}
.campl-promo-teaser .campl-teaser-title{margin-bottom:0}

.campl-navigation-list h3{padding-bottom:10px;}
.campl-navigation-list li{padding-bottom:5px}

.campl-event-cta-container{padding:20px 20px 60px 20px;}
.campl-notifications-container{padding:10px 80px;}
.campl-section-list-children{padding:15px 20px 15px}

/* content containers - decorative */
.campl-footer-navigation{background: url(../assets/bg-footer-navigation-b43255184e0cfa6c59c770dc82161721.png) 100% 0 repeat-y;}
.campl-footer-navigation.last{background:none}

.campl-recessed-banner{margin-bottom:-50px}
.campl-recessed-content{padding-top:50px}
.campl-recessed-carousel{margin-bottom:-50px}

.campl-recessed-content .campl-tertiary-navigation{margin-top:-50px;padding-bottom:50px}

.campl-recessed-sub-title{margin-bottom:-50px;overflow:auto;}

.campl-recessed-secondary-content{margin-top:-36.6%}

.campl-sub-column-right-border{border-right:1px solid #e4e4e4}
.campl-sub-column-left-border{border-left:1px solid #e4e4e4;margin-left:-1px}

.campl-teaser-divider{margin:0;border-bottom:1px solid #e4e4e4;border-top:0}
.campl-dotted-divider{margin:0;border-bottom:2px dotted #020902;border-top:0}

.campl-homepage-teaser{border-right:1px solid #e4e4e4}
.campl-alt{border-right:0}

.campl-heading-container{border-bottom:1px solid #e4e4e4;}

/* 7.0 CUSTOM MODULES -----------------------------------------------------------------------------------------------*/

/* Header */
.campl-global-header{position:relative;z-index:15}

.js .campl-open-menu{background:url(../assets/btn-open-menu-sprite-cd559cc595f9efdf6d05c3fe9c9ea0b8.png) no-repeat 0 0;width:32px;height:33px;float:left;display:none}
.js .campl-open-menu:focus, .js .campl-open-menu:hover, .js .campl-open-menu:active{background-position:0 -33px}

.campl-main-logo{padding:15px;display:block;float:left}
.campl-main-logo:visited{text-decoration:none;border:0}
.campl-homepage-header .campl-main-logo{display:none}

.campl-search-drawer{border-top:1px solid #3f3f3f;position:relative;z-index:5}

/* global site search */
.campl-search-drawer{display:none}
.campl-site-search {padding:15px 0 15px 15px;float:none}
.campl-icon-search-btn{height:32px;width:32px;background:url(../assets/btn-search-sprite-small-e50094913d78e3351b6115325bedf0e5.png) 0 0 no-repeat;display:none}
.campl-icon-search-btn:focus, .campl-icon-search-btn:hover, .campl-icon-search-btn:active{background-position: 0 -32px}
.campl-search-form-wrapper{background:#2c2c2c;height:32px;padding:0 0 0 10px}
.campl-site-search-form{display:block;padding:15px;}
.campl-site-search-form input{background:transparent; color:#9c9c9c;border:0;float:left;padding: 7px 0;width: 92%;}
.campl-site-search-form input.campl-search-submit{width:22px;padding:0;float:right;height:32px}


/* Contextual Search */
.campl-search-input{background-color: #FFFFFF;border: 0; display: inline-block; -moz-box-sizing: border-box;width: 100%;position: relative;margin-bottom: 0; height: 42px;min-height:42px;}
.lt-ie8 .campl-search-input{margin-left: 0;}
.campl-search-input input{border:0;padding:10px 8px 8px;vertical-align: middle;width:75%;float:left;margin-bottom:0}
.campl-search-input input.campl-search-submit{width:42px;padding:0;float:right;height:43px;-webkit-border-radius: 0;margin-right:0;float:right;margin-right:-1px}

/* Header search */
.campl-site-search .campl-search-input{height: 32px;min-height:32px;background-color: #343434;}
.campl-site-search .campl-search-input input{width:65%;padding:7px 0 7px 8px;background-color: #343434;color:#fff}
.campl-site-search .campl-search-input input.campl-search-submit{width:32px;height:32px;padding:0;}


/* global quick links */
.campl-quicklinks{width:100%;margin-top:15px;}
.campl-quicklinks-txt{padding:8px 0 0 8px;display:block;float:left}
.campl-icon-dropdown{height:32px;width:32px;background:url(../assets/btn-quicklinks-sprite-62b928c1489970b2f5e2707032a4a306.png) 0 0 no-repeat;float:right }

.campl-open-quicklinks:focus .campl-icon-dropdown, .campl-open-quicklinks:hover .campl-icon-dropdown{background-position:0 -42px;}
.campl-open-quicklinks{display:block;
	background: #3d3d3d; /* Old browsers */
	background: -moz-linear-gradient(top,  #3d3d3d 0%, #292929 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3d3d3d), color-stop(100%,#292929)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #3d3d3d 0%,#292929 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #3d3d3d 0%,#292929 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #3d3d3d 0%,#292929 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #3d3d3d 0%,#292929 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3d3d3d', endColorstr='#292929',GradientType=0 ); /* IE6-9 */;}

.js .campl-quicklinks-list{display:none} 
.js .campl-quicklinks-open .campl-icon-dropdown{background-position:0 -84px;}
.js .campl-quicklinks-open .campl-quicklinks-list{display:block;z-index:6;position:absolute;background:#2a2a2a;border-top:#000 solid 1px;border-right:0;width:200px }
.js .campl-quicklinks-open li{border-color:#4b4b4b;}

.js .campl-quicklinks-list a, .js .campl-quicklinks-list a:link, .js .campl-quicklinks-list a:visited{padding:8px 8px 8px 16px;display:block;background-position:6px 50%}

/*remove padding from other global nav*/
.js .campl-quicklinks-open .campl-global-navigation-container li{padding:0}


/* Homepage quicklinks */
.campl-homepage-quicklinks{position:relative;right:-60px}
.campl-homepage-quicklinks li a{padding:0 10px 5px 15px;margin-bottom:0;background:url(../assets/icon-fwd-btn-6be0e549306bcafcde64ca8eb6e840ac.png) 0 6px no-repeat;display:block;font-weight:bold}

/* Homepage Events highlight */
.campl-highlight-event-item{border-top:1px solid #e4e4e4}
.campl-highlight-event-item:first-child{border-top:none}
.campl-highlight-date-container{width:55px;float:left;margin:0 10px 5px 0;}
.campl-highlight-date{text-align:center;padding:0px 0 2px 0}
.campl-highlight-day, .campl-highlight-event-link, .campl-highlight-date{display:block}
.campl-highlight-day{position:relative;bottom:-3px}

.campl-highlight-event-details{padding:5px 0 10px 0}
.campl-highlight-event-link{padding:3px 0 0}

/* Footer */
.campl-local-footer, .campl-global-footer{padding:20px 0}
.campl-footer-logo{padding-top:0}
.campl-footer-logo img{padding-bottom:20px}

/* Global Navigation */
.campl-global-navigation{border-left:1px solid #2e2e2e;margin-right:15px;float:left}
.campl-home-link-container{display:none}
.campl-global-navigation li a{padding:25px 20px;border-right:1px solid #2e2e2e;display:block;word-wrap: break-word;}
.lt-ie9 .campl-global-navigation li a{padding:25px 10px}
.campl-global-navigation li a:focus, .campl-global-navigation li a:hover, .campl-global-navigation li a:active,
.campl-global-navigation li.campl-selected a{border-left:1px solid #737373;border-right:1px solid #737373;margin-left:-1px;background:#171717;position:relative;z-index:7;}
.campl-global-navigation li.campl-selected a{margin-bottom:-1px;padding-bottom:26px}

.js .campl-global-navigation-drawer, .js .campl-global-navigation-outer{display:none}
.js .campl-drawer-open, .js .campl-navigation-open{display:block}

/*mega drop down*/
.campl-global-navigation-drawer{z-index:4;position:relative;border-top:1px solid #3f3f3f;}
.campl-global-navigation-container li, .campl-global-navigation-container li li{padding:10px 0} 
.campl-global-navigation-container.last li{padding:15px 0}
.campl-global-navigation-secondary-with-children li{padding:10px 0 0}
.campl-global-navigation-header-container li{padding:20px 0 20px 20px}
.campl-global-navigation-container li a, .campl-global-navigation-header-container li a{background:url(../assets/icon-fwd-btn-6be0e549306bcafcde64ca8eb6e840ac.png) no-repeat 0 50%;padding-left:10px }

.campl-global-navigation-tertiary{border:0;}
.campl-global-navigation-header-container .campl-global-navigation-tertiary li{padding-left:20px}
.campl-global-navigation-container p,  .campl-global-navigation-tertiary{padding-top:20px;margin-bottom:0}

.campl-global-navigation-outer{position:relative}
.campl-close-menu{position:absolute;height:30px;top:0;right:0;background:#000 url(../assets/icon-close-menu-btn-e811a08e226a06d9f049c8d248d03d32.png) 90% 50% no-repeat;padding:20px 50px 7px 12px;border-left:1px solid #3f3f3f;}

/* Local Navigation */

/* add some basic styling to help non javascript users navigate the site */
.campl-menu-btn, .campl-menu-indicator{display:none;}
.campl-local-navigation li{margin:0}

.campl-local-navigation a{display:block;}

.campl-local-navigation ul ul a{padding-left:40px;}
.campl-local-navigation ul ul ul a{padding-left:60px;}
.campl-local-navigation ul ul ul ul a{padding-left:80px;}

.js .campl-local-navigation{position:relative;z-index:13}
.js .campl-local-navigation .campl-local-navigation-container { margin: -1px 0 0 0 ;width: auto;}
.js .campl-local-navigation li.campl-back-link, .js .campl-local-navigation li.campl-back-link a{display: none;font-size: 0;height: 0;visibility: hidden;position:absolute}
.js .campl-local-navigation li { display: inline;float: left;margin:0 0 -1px 0}
.js .campl-local-navigation li li{margin:0}
.js .campl-local-navigation li.campl-title{display:none}
.lt-ie8 .campl-local-navigation li {margin:0}
.js .campl-local-navigation a{padding:10px 20px;}
.js .campl-local-navigation li.campl-sub>a{padding:10px 40px 10px 10px;margin:0}
.js .campl-local-navigation li.campl-sub>a{background-image:url(../assets/bg-local-navigation-sub-arrow-d101a500a4d23485d1d1fbc598cbee03.png);background-repeat:no-repeat;background-position:100% 50% ;}

.js .campl-local-navigation li > ul {margin-top:-1px}
.js .campl-local-navigation li ul {left: -9999px;position: absolute; width: 235px;z-index:14;}
.js .campl-local-navigation li.campl-hover a{display: block;}

.js .campl-local-navigation li.campl-hover ul {left: auto;}
.js .campl-local-navigation li.campl-hover ul {display: block;float: none;}
.js .campl-local-navigation li.campl-hover li{float: none;}
.js .campl-local-navigation li.campl-hover li a{display: block;padding:10px 40px 10px 10px}

.js .campl-local-navigation li.campl-hover ul li ul {left: -9999px;}
.js .campl-local-navigation li.campl-hover ul li.campl-hover ul{left: 235px;top: 0;}


/* campl-vertical-breadcrumb - tertiary navigation */
.campl-tertiary-navigation{position:relative;z-index:11}
.campl-tertiary-navigation-structure{background:#fff;border-bottom:1px solid #e4e4e4;}

.campl-vertical-breadcrumb li{line-height:16px;position:relative;word-wrap:break-word}
.campl-vertical-breadcrumb a{padding:16px 30px 16px 20px;background:#fafafa url(../assets/bg-vertical-breadcrumb-up-arrow-cb4604ced90dc3d917dc0e29eccdb444.png) no-repeat 95% 50%;border-bottom:1px solid #e4e4e4;display:block;position:relative;}
.campl-vertical-breadcrumb-indicator{z-index:9;display:block;width:19px;height:10px;background:url(../assets/bg-vertical-breadcrumb-indicator-arrow-bb60588cb7f92717be999bd8901a4577.png) no-repeat 0 0;position:absolute;bottom:-10px;left:20px;}

.campl-vertical-breadcrumb a:focus, .campl-vertical-breadcrumb a:hover, .campl-vertical-breadcrumb a:active{background:#efefef url(../assets/bg-vertical-breadcrumb-up-arrow-cb4604ced90dc3d917dc0e29eccdb444.png) no-repeat 95% 50%;text-decoration:none}
.campl-vertical-breadcrumb a:focus .campl-vertical-breadcrumb-indicator, 
.campl-vertical-breadcrumb a:hover .campl-vertical-breadcrumb-indicator,
.campl-vertical-breadcrumb a:active .campl-vertical-breadcrumb-indicator{background:url(../assets/bg-vertical-breadcrumb-indicator-arrow-over-1177244d2e2b6fec86b619150508271b.png) no-repeat 0 0;}

.campl-vertical-breadcrumb-navigation{display:block;padding:5px 20px;font-weight:bold }
.campl-vertical-breadcrumb-navigation .campl-selected > a,
.campl-vertical-breadcrumb-navigation .campl-selected > a:focus,
.campl-vertical-breadcrumb-navigation .campl-selected > a:hover,
.campl-vertical-breadcrumb-navigation .campl-selected > a:visited {color:#171717;} 
.campl-vertical-breadcrumb-navigation li{padding:10px 0;border-bottom:1px solid #e4e4e4}
.campl-vertical-breadcrumb-navigation li:last-child{border-bottom:0}

.campl-vertical-breadcrumb-children{border-bottom:0;margin:10px 0 0; }
.campl-vertical-breadcrumb-children li{padding:5px 5px 5px 0;border-bottom:0;font-weight:normal}
.campl-vertical-breadcrumb-children a{border-bottom:0;background:#fff url(../assets/bg-vertical-breadcrumb-right-arrow-ac8b4f8583943885077615e593d4777b.png) no-repeat 0 50%;font-weight:normal;padding:0 0 0 15px}	
.campl-vertical-breadcrumb-children a:focus, .campl-vertical-breadcrumb-children a:hover, .campl-vertical-breadcrumb-children a:active{background:#fff url(../assets/bg-vertical-breadcrumb-right-arrow-ac8b4f8583943885077615e593d4777b.png) no-repeat 0 50%; text-decoration:underline}		
				
.campl-vertical-breadcrumb-children .campl-selected a{cursor:default}
.campl-vertical-breadcrumb-children .campl-selected a:focus, .campl-vertical-breadcrumb-children .campl-selected a:hover{text-decoration:none}

/* Breadcrumbs */
.campl-breadcrumb{color:#fff;padding-bottom:20px}
.campl-breadcrumb li{padding:0 2px 0 17px;background: url(../assets/bg-breadcrumb-link-aec747193ca5a549ed6d0e5f6e94b29c.png) 5px 50% no-repeat;white-space:nowrap;}
.campl-breadcrumb li:first-child, .campl-breadcrumb li.first-child{background:none;padding:0}
.campl-breadcrumb .campl-home{background: url(../assets/icon-breadcrumb-home-e8be9ede021059b35041ea5bf2a770c5.png) 0 0 no-repeat;width:17px; height:17px;padding:0}
.campl-breadcrumb .campl-current{margin:0}
.campl-breadcrumb a:visited, .campl-mobile-parent a:visited{border:0;}

.campl-mobile-parent{display:none;position:relative;padding:0 0 0 25px;margin-bottom:10px }
.campl-mobile-parent .campl-menu-indicator{left:0}

/* Page header inside content */
.campl-page-title{clear:both}
.campl-co-branding-logo{float:left;padding:0 20px 20px 0}
.campl-co-branding-container .campl-page-title{clear:none}
.campl-page-sub-title{position:relative;z-index:0}

/* Carousel */
.campl-carousel{background:#fff;text-align:center;position:relative;}
.campl-carousel .campl-carousel-container{position:relative;overflow:hidden;}
.campl-carousel .campl-slides{left:0;margin:0;overflow:hidden;padding:0;position:relative;top:0;}

.campl-slides, .campl-slide {padding:0;margin:0}
.campl-carousel li{position:relative;float:left;}
.campl-carousel a{overflow:hidden;}

.campl-carousel-controls{position:absolute;bottom:0;right:0}
.campl-carousel-controls li{width:50px;height:50px;margin-left:1px;}
.campl-carousel-control-btn{width:50px;height:50px;display:block;position:relative;}
.campl-carousel-control-btn .campl-arrow-span{width:25px;height:25px;background-position:0 0;background-repeat:no-repeat;display:block;position:absolute;left:50%;margin-left:-12.5px;top:50%;margin-top:-12.5px }

.campl-carousel-controls .campl-next .campl-arrow-span {background-image:url(../assets/btn-carousel-next-bb1f981db4c41d1199dab15a74b957ce.png)}
.campl-carousel-controls .campl-previous .campl-arrow-span{background-image:url(../assets/btn-carousel-prev-d6e24b6d036de8361e9f93d86338cd54.png)}

.campl-slide{position:relative}
.campl-slide-caption{background:url(../assets/bg-carousel-caption-6db8d1690abb366eb87e7d389335b90a.png) repeat 0 0;position:absolute;bottom:0;right:102px;color:#fff;padding:12px 16px 13px;left:20px;text-align:left}

.campl-slide-caption-txt{margin-right:40px;}
.campl-carousel-pagination{position:absolute;right:16px;bottom:16px}

/* single banner with caption */
.campl-banner .campl-slide-caption{right:20px}
.campl-banner .campl-slide-caption-txt{margin-right:0}


/* Related links */
.campl-related-links li {padding:10px 20px 10px 0; border-bottom:1px solid #e4e4e4;margin-bottom:0}

/* Listing item */
.campl-listing-item{clear:both;position:relative;border-bottom:1px dotted #000000;margin-bottom:10px}
.campl-listing-item:last-child{border:0}

/* news listing */
.campl-news-listing{border-bottom:1px dashed #b5b5b5;}

/* events */
.campl-event-details{border:1px solid #e4e4e4;border-width:1px 0 1px 0;}
.campl-event-details-dl{float:left;margin:0;padding:0}
.campl-event-details-dl dt, .campl-event-details-dl dd{float:left;margin:0;padding:5px 10px 5px 0;}

.campl-events-cta{float:right}
.campl-events-cta li{margin-left:10px}

/* paging controls */
.campl-paging{margin-top:-38px}
	
.campl-paging-btn{width:38px;height:38px;display:block;position:relative;}
.campl-arrow-span{width:8px;height:11px;background-position:0 0;background-repeat:no-repeat;display:block;position:absolute;left:50%;margin-left:-3px;top:50%;margin-top:-5px }

.campl-paging-btn:focus, .campl-paging-btn:hover{background:#454545}
.campl-next .campl-arrow-span {background-image:url(../assets/icon-fwd-btn-larger-a342ba81ca6782f825c45e2283996ec0.png)}
.campl-previous .campl-arrow-span{background-image:url(../assets/icon-back-btn-larger-d97b559988662fcb3c7d72c0e266c99e.png)}

.campl-current-date{color:#fff;line-height:38px;margin:0 39px;position:relative;right:39px;left:0;background:#171717;}
.lt-ie8 .campl-current-date{padding:2px 0 3px 0}
.campl-paging-container{text-align:center;}	
.campl-paging .campl-previous-li{float:left;border-right:1px solid #fff}
.campl-paging .campl-next-li{float:right;border-left:1px solid #fff}

/* search results */
.campl-filter-navigation{margin-left:-7px;padding:8px 0 5px}
.campl-filter-navigation li{border-right:1px solid #444;line-height:14px;padding:0 7px;font-weight:bold}
.campl-filter-navigation li:last-child{border:0}

/* teasers */
.campl-teaser {clear:both}
.campl-teaser p{margin-bottom:20px;}

.campl-teaser-img-link{position:relative;display:block}
.campl-overlay{width:60px;height:60px;background: url(../assets/icon-media-sprite-90cc73ccc06eb46f2d292c7993226a30.png) 0 0 no-repeat;position:absolute;bottom:0;right:0;}
.campl-audio-overlay{background-position:-10px -10px}
.campl-video-overlay{background-position:-11px -108px}
.campl-teaser-img-link:focus .campl-audio-overlay, .campl-teaser-img-link:hover .campl-audio-overlay{background-position:-97px -10px}
.campl-teaser-img-link:focus .campl-video-overlay, .campl-teaser-img-link:hover .campl-video-overlay{background-position:-98px -108px}

.campl-focus-teaser{margin-bottom:20px;position:relative}
.campl-focus-teaser-img{width: 41.6667%;float: left;margin-right: 0;padding: 0;}
.campl-focus-teaser-txt{width: 58.3333%;float: left;margin-right: 0;padding: 0;}

.lt-ie8 .campl-focus-teaser-img{width: 41.1667%;}
.lt-ie8 .campl-focus-teaser-txt{width: 58.1333%;}

.campl-vertical-teaser-img{position:relative}
.campl-topic{position:absolute;top:0;left:0;z-index:12}

/* .pagination */
.campl-pagination {height: 36px;margin: 18px 0;position:relative;}
.campl-pagination ul {display: inline-block;margin-bottom: 0;margin-left: 0;}
.lt-ie8 .campl-pagination ul {display: inline;zoom: 1}
.campl-pagination li {display: inline;}
.campl-pagination a, .campl-elipsis{float: left;padding: 5px 7px;line-height: 12px;text-decoration: none;border: 1px solid #d6d6d6;margin:0 2px;}
.campl-elipsis{border:0}
.campl-pagination a:hover,.campl-pagination .campl-active a {background-color: #f2f2f2;color:#000000;}

.campl-pagination .campl-active a {cursor: default;font-weight:bold;}

.campl-pagination-centered {text-align: center;}

.campl-pagination-right {text-align: right;}

.campl-pagination .campl-pagination-btn{border:0;width:8px;height:14px;display:block;position:relative;background:#000}
.campl-pagination .campl-pagination-btn:hover{background:#454545}
.campl-pagination .campl-previous-li{left:0;top:50%;margin-top:-6px}
.campl-pagination .campl-next-li{right:0;top:50%;margin-top:-6px}

/* navigation list */
.campl-list-container{text-align:center}
.campl-mobile-list-layout{display:none}
.campl-desktop-list-layout{padding-bottom:10px}
.campl-desktop-list-layout li{background:url(../assets/bg-navigation-divider-1a5e899ab53467e934e6f0d673681417.png) center right no-repeat;padding:0 14px 0 5px;display:inline-block; }
.lt-ie8 .campl-desktop-list-layout li{padding:0 10px 0 5px;display:inline}
.campl-desktop-list-layout li:last-child{background:none}

.lt-ie8 .campl-desktop-list-layout li{padding:0 4px 0 4px}
.lt-ie8 .campl-desktop-list-layout li a{padding:0 8px 0 0;top:2px}
.lt-ie8 .campl-desktop-list-layout a .campl-list-indicator{bottom:-28px;margin-left:-26px}

.campl-desktop-list-layout a{position:relative;}
.campl-desktop-list-layout a .campl-list-indicator{display:none;height:21px;width:41px;background:url(../assets/bg_list_indicator-cd8c9f8698875c066b4cd72c26cd04e0.png) 0 0 no-repeat;position:absolute;bottom:-35px;left:50%;margin-left:-22px}
.campl-desktop-list-layout a:focus .campl-list-indicator, .campl-desktop-list-layout a:hover .campl-list-indicator, .campl-desktop-list-layout .campl-selected .campl-list-indicator {display:block}

/* Tables */
td, th { vertical-align: top; }
table {max-width: 100%;background-color: transparent;border-collapse: collapse;border-spacing: 0;}
.campl-table {width: 100%;margin-bottom: 18px;}
.campl-table th,.campl-table td {padding: 10px 15px;line-height: 18px;text-align: left;vertical-align: top}
.campl-table th {font-weight: bold;}
.campl-table thead th {vertical-align: bottom;}
.campl-table caption + thead tr:first-child th,
.campl-table caption + thead tr:first-child td,
.campl-table colgroup + thead tr:first-child th,
.campl-table colgroup + thead tr:first-child td,
.campl-table thead:first-child tr:first-child th,
.campl-table thead:first-child tr:first-child td {border-top: 0;}

.campl-table-condensed th,.campl-table-condensed td {padding: 4px 5px;}
.campl-table-bordered {border-left:1px solid #e4e4e4;border-right:1px solid #e4e4e4;border-collapse: separate;border-left: 0;}
.lt-ie8 .campl-table-bordered {border-collapse: collapsed;}

.campl-table-bordered td { border-left: 1px solid #e4e4e4;}
.campl-table-bordered caption + thead tr:first-child th,
.campl-table-bordered caption + tbody tr:first-child th,
.campl-table-bordered caption + tbody tr:first-child td,
.campl-table-bordered colgroup + thead tr:first-child th,
.campl-table-bordered colgroup + tbody tr:first-child th,
.campl-table-bordered colgroup + tbody tr:first-child td,
.campl-table-bordered thead:first-child tr:first-child th,
.campl-table-bordered tbody:first-child tr:first-child th,
.campl-table-bordered tbody:first-child tr:first-child td {border-top: 0;}

.campl-table-heading{display:none;font-weight:bold;padding-right: 10px; white-space: normal;}

caption{background:#fff;padding:5px 0}
.campl-responsive-table{position:relative}
.campl-expanded-table{width:1074px;}
.campl-full-width-table{width:1024px; max-width:1024px;}



/* Calendar - Zebra_DatePicker: a lightweight jQuery date picker plugin*/
.Zebra_DatePicker table{width:100%;border:0;}

.Zebra_DatePicker .dp_footer{display:none}

.Zebra_DatePicker .dp_daypicker td{ text-align:right;padding:0 3px 9px 6px;background:#e1e1e1;border:1px solid #fff;border-width:0px 1px 0 0;cursor:pointer}
.Zebra_DatePicker .dp_daypicker th{text-align:center;background:#fff;color:#444;font-weight:normal;padding:5px}
.Zebra_DatePicker .dp_daypicker tr{border-bottom:1px solid #fff}
.Zebra_DatePicker .dp_daypicker tr:last-child{border:0}
.Zebra_DatePicker .dp_daypicker tr td:last-child{border-right:0;}

.Zebra_DatePicker .dp_header{border-top:1px solid #d5d3d2}
.Zebra_DatePicker .dp_header td { color: #FFF }

.Zebra_DatePicker .dp_header .dp_previous,
.Zebra_DatePicker .dp_header .dp_next{ width: 30px }

.Zebra_DatePicker .dp_header .dp_caption,
.Zebra_DatePicker .dp_header .dp_previous,
.Zebra_DatePicker .dp_header .dp_next{ text-align:center;background:#fff;color:#444;padding:5px}

.Zebra_DatePicker .dp_header .dp_hover{ background: #222; color: #FFF; }
.Zebra_DatePicker .dp_header .dp_blocked{ color: #888; cursor: default }

.Zebra_DatePicker td.dp_not_in_month{ background: #edeaea; color: #b4b3b3; cursor: default }
.Zebra_DatePicker td.dp_selected{ background: #222; color: #FFF !important }

.Zebra_DatePicker td.dp_current {background:#888888; color: #fff }
.Zebra_DatePicker td.dp_disabled_current{ background: #edeaea; color: #b4b3b3;}
.Zebra_DatePicker td.dp_disabled, .Zebra_DatePicker td.dp_weekend_disabled{background: #edeaea; color: #b4b3b3; cursor: default }
.Zebra_DatePicker td.dp_hover{ background: #222; color: #FFF }

.js .campl-event-dates{display:none}
.dp_highlighted a{display:block;position:relative;}
.campl-event-indicator{width:5px;height:5px;position:absolute;bottom:-4px;left:-2px;display:block;background:#f3f3f3}

/* Tabs and pills */
.campl-nav {margin-left: 0;margin-bottom: 20px;list-style: none;}
.campl-nav > li > a {display: block;}
.campl-nav > li > a:hover {text-decoration: none;background-color: #eeeeee;}
.campl-nav > li > a:visited {border:0}

.campl-nav-tabs{padding-left:20px;border-bottom:2px solid  #0088cc;}
.campl-nav-tabs,.campl-nav-pills {margin-bottom:0}
.lt-ie8 .campl-nav-tabs, .lt-ie8 .campl-nav-pills {zoom: 1;}

.campl-nav-tabs:before,.campl-nav-pills:before,
.campl-nav-tabs:after,.campl-nav-pills:after {display: table;content: "";line-height: 0;}
.campl-nav-tabs:after,.campl-nav-pills:after {clear: both;}
.campl-nav-tabs > li,.campl-nav-pills > li {float: left;margin:0;word-wrap: break-word;padding-bottom:0}

.lt-ie8 .campl-nav-tabs > li, .lt-ie8 .campl-nav-pills > li {white-space:nowrap;}

.campl-nav-tabs > li > a,
.campl-nav-pills > li > a {padding-right: 12px;padding-left: 12px;margin-right: 2px;line-height: 20px;}
.lt-ie8 .campl-nav-pills > li a{padding-bottom:15px}

.campl-nav-tabs > li > a {padding-top: 8px;padding-bottom: 8px;line-height: 20px;}
.campl-nav-tabs > li > a:hover {border-color: #eeeeee #eeeeee #dddddd;}

.campl-nav-tabs > .active > a,
.campl-nav-tabs > .active > a:hover {cursor: default;color: #ffffff;background-color: #0088cc;}

.campl-nav-pills > li > a {padding-top: 8px;padding-bottom: 8px;margin-top: 2px;margin-bottom: 2px;}

.campl-nav-pills > .active > a,
.campl-nav-pills > .active > a:hover {color: #ffffff;background-color: #0088cc;}

.tab-content {overflow: auto;}
.tab-content > .tab-pane, .pill-content > .pill-pane {display: none;}
.tab-content > .active,.pill-content > .active {display: block;}


/* Notifications */
.campl-notifications-panel{border-bottom:4px solid red;margin-bottom:20px;position:relative;
-moz-box-shadow:  0 2px 1px 0 #eee;-webkit-box-shadow: 0 2px 1px 0 #eee;box-shadow: 0 2px 1px 0 #eee;}
.campl-notifications-panel p{padding-top:5px}

.campl-close-panel{width:23px;height:23px;position:absolute;top:4px;right:4px;background:url(../assets/btn-close-panel-1c30df99d175809758fcb93070f9702d.png) 0 0 no-repeat}

.campl-notifications-icon{float:right;font-weight:bold;background-repeat:no-repeat;background-position:center left;padding:5px 5px 5px 30px ;margin-bottom:0}
.campl-success-icon{background-image:url(../assets/icon-success-f265cd10ff26999ab519c478b50adcf2.png)}
.campl-warning-icon{background-image:url(../assets/icon-warning-d69c6c3e7ea813339ed9e7991aaa11b3.png);padding-left:35px}
.campl-alert-icon{background-image:url(../assets/icon-alert-c8f4ae1533bf6586a653b1659d051a7b.png)}
.campl-information-icon{background-image:url(../assets/icon-information-25324264b2a5826542454840bb21164d.png)}


/* Tooltips */
#tooltip{text-align: center;color: #f7fcff;background: #4f4f4f;position: absolute;z-index: 100;padding: 15px;border:1px solid #e4e4e4;
	-moz-box-shadow:  0 2px 1px 0 #eee;-webkit-box-shadow: 0 2px 1px 0 #eee;box-shadow: 0 2px 1px 0 #eee;
	}
/* triangle decoration */
#tooltip:after{width: 0;height: 0;border-left: 10px solid transparent;border-right: 10px solid transparent;border-top: 10px solid #4f4f4f;
	content: '';position: absolute;left: 50%;bottom: -10px;margin-left: -10px;
}
#tooltip.top:after{border-top-color: transparent;border-bottom: 10px solid #f7fcff;top: -20px;bottom: auto;}
#tooltip.left:after{left: 10px;margin: 0;}
#tooltip.right:after{right: 10px;left: auto;margin: 0;}


/* Partnershp branding */
.campl-partnership-branding{border-top:4px solid #eee}
.campl-logo-container{border-bottom:dotted 2px #e4e4e4;position:relative}
.campl-logo-container:last-child{border:0}
.campl-logo-list li{margin-top:25px;margin-right:40px}
.campl-branding-title{position:absolute;top:1px;right:0;}


/* Section list */
.campl-section-list-heading{display:block;border-right:1px solid #e4e4e4;margin-bottom:0}
.campl-section-list-children{border-right:1px solid #e4e4e4;margin-bottom:0}
.campl-section-list{margin-bottom:0}
.campl-section-list li{padding: 5px 0}
.campl-section-list-children li{border-bottom:1px solid #e4e4e4;font-weight:bold}
.campl-section-list-children li li, .campl-section-list-children li:last-child{border:0;font-weight:normal}
.lt-ie9 .campl-section-list-children li.campl-last{border:0;}
.lt-ie9 .campl-section-list-children li li{font-weight:normal}


/* 8.0 MEDIA QUERIES ------------------------------------------------------------------------------------------------*/

/* larger screens */
@media only screen and (min-width: 1200px){
	.campl-wrap{width:1177px}
}

/* larger screens */
@media only screen and (max-width: 1199px){

.campl-wrap{width:100%; }
.campl-homepage-quicklinks{position:static}
}

/* standard desktop screens */
@media only screen and (max-width: 1280px){
	.campl-main-logo{padding:15px 10px}

	img.campl-float-right{float:none;margin:10px 0 20px 0;}
	img.campl-float-left{float:none;margin:10px 0 20px 0;}

	.campl-desktop-list-layout li{padding:0 11px 0 1px;}
}


/* tablet portrait to standard desktop */
@media (min-width: 768px) and (max-width: 1280px) {

}
@media (max-width: 1023px) {
	/*Change main nav*/
	.campl-global-navigation{width:280px}
	.campl-global-navigation li{width:33%}
	.campl-header-container{width:auto;float:left}
}


/* font sizes to handle calendar in third column */
@media (min-width: 1081px) and (max-width: 1280px){
	.campl-column3 .Zebra_DatePicker table{font-size:10px}
	.campl-column3 .Zebra_DatePicker .dp_daypicker td{ padding: 0 2px 5px 2px;}
	.campl-column3 .campl-event-indicator{bottom:0;left:0;}
}

@media (min-width: 1024px) and (max-width: 1080px) {

	.campl-site-search .campl-search-input input{width:50%}
	
	.campl-column3 .Zebra_DatePicker table{font-size:9px}
	.campl-column3 .Zebra_DatePicker .dp_daypicker td{ padding: 0 1px 2px 1px;}
	.campl-column3 .campl-event-indicator{bottom:1px;left:1px;}
}


/* tablet landscape */
@media only screen and (max-width: 1150px){

	.campl-site-search {padding-right:15px}
	.campl-global-navigation li a{padding:25px 10px}

	.campl-pagination a, .campl-elipsis{padding: 8px 15px;line-height: 20px;}
	
	.campl-pagination .campl-pagination-btn{width:11px;height:22px;}
	.campl-pagination .campl-pagination-btn:focus, .campl-pagination .pagination-btn:hover{background:#454545}
	.campl-pagination .campl-previous-li{left:0;top:50%;margin-top:-10px}
	.campl-pagination .campl-next-li{right:0;top:50%;margin-top:-10px}
	
	.campl-column3 .Zebra_DatePicker table{font-size:9px}
	.campl-column3 .Zebra_DatePicker .dp_daypicker td{ padding: 0 1px 2px 1px;}
	.campl-column3 .campl-event-indicator{bottom:1px;left:1px;}

}

/* tablet portrait */
@media (min-width: 768px) and (max-width: 979px) {
	.campl-column3 .Zebra_DatePicker table{font-size:8px}
	.campl-site-search .campl-search-input input{width:50%}
	blockquote.campl-float-right {display:none}
}

/* anything smaller than tablet portrait ie. mobile devices */
@media (max-width: 767px) {
	
	.campl-page-header h1, .campl-carousel-content p, .campl-page-title{font-size: 28px;line-height: 34px;margin-bottom:0}
	.campl-page-sub-title h2, .campl-sub-title{font-size: 26px;line-height: 32px;color:#fff;margin-bottom:0}
	.campl-homepage-content h2{font-size: 26px;line-height: 32px;margin-bottom:0}
	h1, .campl-light-heading{font-size: 26px;line-height: 32px;}
	h2, .campl-slide-caption-txt,  legend, .campl-highlight-day{font-size: 24px;line-height: 30px;}
	
	h3{font-size: 22px;line-height: 28px;}
	h4,.campl-branding-title, .campl-sub-section-page .campl-page-header h1,
 	.campl-sub-section-page .campl-page-title {font-size:20px;line-height: 26px}
	h5, .campl-vertical-breadcrumb, .campl-enlarged-text, .campl-teaser-title,  .campl-primary-cta, .btn, 
	.campl-local-footer h3, .campl-global-footer h3, .campl-desktop-list-layout, .campl-local-navigation {font-size:18px;line-height: 24px}
	h6, .campl-simple-heading, .campl-listing-title, .campl-news-listing .campl-datestamp, .campl-current-date{font-size:17px;line-height: 23px}
 	.campl-nav-tabs, .campl-nav-pills{font-size:17px;line-height: 20px}
	.campl-nav-tabs > li > a, .campl-nav-pills > li > a {line-height:20px}
	body, label, input, button, select, textarea, .campl-global-navigation, h4, h5, h6, .campl-topic{font-size: 16px; line-height: 22px;}
	.campl-pagination, .highlight, .campl-highlight-alert, .campl-datestamp, .campl-search-term, .campl-topic{font-size:15px;line-height: 20px}

	p, li {margin:0 0 15px 0}
	
	ul, ol{padding: 0;margin: 15px 0 9px 15px;}

	img.campl-float-right{margin:10px 0 10px 0;}
	img.campl-float-left{margin:10px 0 10px 0;}

	blockquote.campl-float-right {display:none}
	
	textarea,
	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="color"],
	.campl-uneditable-input,
	.campl-input-block-level {height: 22px;}
	.campl-input-block-level {min-height: 32px;}
	
	select, input[type="file"] { height: 32px;line-height: 32px;}

	/*reset fixed width columns back to full width for mobile */
	  [class*="campl-column"], .campl-column{
	    float: none;
	    display: block;
	    width: auto;
	    margin-left: 0;
	  }

	.campl-wrap{width:100%}
	.campl-search-container{padding:20px;}
	.campl-no-left-padding{padding: 0 20px 20px 20px}
	.campl-recessed-secondary-content {margin-top:0}
	.campl-spacing-column,  .campl-sub-section-title{display:none}
	.campl-homepage-logo-header{display:none}
	.campl-footer-navigation{background: none;}
	.campl-footer-navigation ul{display:none}
	.campl-footer-navigation ul.campl-page-children, .campl-footer-navigation ul.campl-global-footer-links{display:block}
	
	.campl-horizontal-teaser-img{padding:0 0 20px 0 }
	.campl-horizontal-teaser-txt{padding:0 0 0 0 }
	
	.campl-navigation-open .campl-row{margin-left:260px}
	
	.campl-site-search{float:right;}
	
	.js .campl-search-drawer, .campl-close-menu{display:none}
	.js .campl-search-open{display:block}
	
	.campl-site-search .campl-search-input{display:none}
	.campl-icon-search-btn{display:block}
	
	.campl-main-logo {padding:15px 0;margin-left:-89px;position:absolute;left:50%}
	.campl-homepage-header .campl-main-logo{display:block}

	.campl-home-link-container{display:block;}
	.campl-home-link-container a{display:block;padding:21px 15px;border-right:1px solid #2f2f2f;font-weight:bold;}
	.campl-global-navigation-container li a, .campl-global-navigation-header-container li a{background:none}
	.campl-home-link-container a, .campl-quicklinks-list li a, .campl-global-navigation-outer {
		background:url(../assets/bg-mobile-nav-border-d0ed877b87cdfb05e5f0606d5cc5f42a.png) repeat-x bottom left;
	}
	.campl-global-navigation-outer {padding-bottom:2px}
	
	.campl-home-link-container a:focus, .campl-global-navigation-mobile-list a:focus, .js .campl-quicklinks-list a:focus,
	.campl-home-link-container a:hover, .campl-global-navigation-mobile-list a:hover, .js .campl-quicklinks-list a:hover{text-decoration:none}
	
	.campl-home-link-container, .campl-global-navigation-mobile-list, .campl-quicklinks-list li {margin:0;
		background:#323232;
		background-image: linear-gradient(bottom, rgb(50,50,50) 17%, rgb(59,59,59) 59%);
		background-image: -o-linear-gradient(bottom, rgb(50,50,50) 17%, rgb(59,59,59) 59%);
		background-image: -moz-linear-gradient(bottom, rgb(50,50,50) 17%, rgb(59,59,59) 59%);
		background-image: -webkit-linear-gradient(bottom, rgb(50,50,50) 17%, rgb(59,59,59) 59%);
		background-image: -ms-linear-gradient(bottom, rgb(50,50,50) 17%, rgb(59,59,59) 59%);

		background-image: -webkit-gradient(
			linear,
			left bottom,
			left top,
			color-stop(0.17, rgb(50,50,50)),
			color-stop(0.59, rgb(59,59,59))
		);}
	
	.campl-home-link-container:hover, .campl-quicklinks-list li:hover,
	.campl-home-link-container:focus, .campl-quicklinks-list li:focus{
		background:#2a2a2a;
		background-image: linear-gradient(bottom, rgb(36,36,36) 20%, rgb(42,42,42) 80%);
		background-image: -o-linear-gradient(bottom, rgb(36,36,36) 20%, rgb(42,42,42) 80%);
		background-image: -moz-linear-gradient(bottom, rgb(36,36,36) 20%, rgb(42,42,42) 80%);
		background-image: -webkit-linear-gradient(bottom, rgb(36,36,36) 20%, rgb(42,42,42) 80%);
		background-image: -ms-linear-gradient(bottom, rgb(36,36,36) 20%, rgb(42,42,42) 80%);

		background-image: -webkit-gradient(
			linear,
			left bottom,
			left top,
			color-stop(0.2, rgb(36,36,36)),
			color-stop(0.8, rgb(42,42,42))
		);
		} 
	
	.campl-global-navigation-header-container{padding:0 20px 0;margin:0}
	.campl-global-navigation-container{padding:0 20px 20px;margin:0;border-right:0}
	.campl-global-navigation-header-container li, .campl-global-navigation-container li {padding:0;border-bottom:2px solid #212121;margin:0}
	.campl-global-navigation-header-container li{padding:0}
	.campl-global-navigation-container li {padding:0 ;margin:0}

	.campl-global-navigation-container {padding:0}
	.campl-global-navigation-container.last li{padding:0}

	.campl-global-navigation-header-container li a{position:relative;left:-20px;font-weight:bold;padding:15px;display:block;margin-right:-40px}
	.js .campl-quicklinks-list a, .js .campl-quicklinks-list a:link, .js .campl-quicklinks-list a:visited{ padding: 15px 15px 20px;}
		
	.campl-global-navigation-container li a{display:block;padding:10px 20px}
	
	.campl-global-navigation-header-container li a:focus, .campl-global-navigation-header-container li a:hover,
	.campl-global-navigation-container li a:focus, .campl-global-navigation-container li a:hover{
		background:#292929;
		background-image: linear-gradient(bottom, rgb(40,40,40) 20%, rgb(41,41,41) 80%);
		background-image: -o-linear-gradient(bottom, rgb(40,40,40) 20%, rgb(41,41,41) 80%);
		background-image: -moz-linear-gradient(bottom, rgb(40,40,40) 20%, rgb(41,41,41) 80%);
		background-image: -webkit-linear-gradient(bottom, rgb(40,40,40) 20%, rgb(41,41,41) 80%);
		background-image: -ms-linear-gradient(bottom, rgb(40,40,40) 20%, rgb(41,41,41) 80%);

		background-image: -webkit-gradient(
			linear,
			left bottom,
			left top,
			color-stop(0.2, rgb(40,40,40)),
			color-stop(0.8, rgb(41,41,41))
		);}
	
	.campl-global-navigation-container{font-weight:normal}
	
	.campl-quicklinks-list{padding:3px 0 0 0 ;background:url(../assets/bg-mobile-nav-border-d0ed877b87cdfb05e5f0606d5cc5f42a.png) repeat-x top left;font-weight:bold;position:relative;top:-2px}

	.campl-quicklinks-list li{padding:0;border-bottom:0}
	.campl-quicklinks-list li a{padding:10px 10px 10px 0;}
	
	
	.js .campl-quicklinks-list a, .js .campl-quicklinks-list a:link, .js .campl-quicklinks-list a:visited{background-position:bottom left}
	
	
	.campl-global-navigation-container p, .campl-global-navigation-tertiary, .campl-global-navigation, .js .campl-quicklinks, .campl-tertiary-navigation{display:none}
	.js .campl-quicklinks-list, .js .campl-open-menu, .js .campl-global-navigation-drawer, .js .campl-global-navigation-outer{display:block}

	.campl-global-navigation-drawer{width:260px;position:absolute;left:-260px;top:-1px;}

	/* styles for menu opening navigation - ipad portrait and lower */
	.js .campl-local-navigation li.campl-back-link, .js .campl-local-navigation li.campl-back-link a {display: block;font-size: inherit;height: auto;visibility: visible;position:static}

	.js .campl-local-navigation-container a{padding: 15px 30px 15px 25px;}
	.js .campl-local-navigation-container li.campl-sub>a{background-image:none;  padding: 15px 30px 15px 25px;}

	.js .campl-menu-btn{margin:0;display:block;}

	.campl-menu-indicator{height:20px;width:20px;position:absolute;top:50%;left:10px;display:block;margin-top:-10px;border-radius:1px; -webkit-border-radius:1px;background-repeat:no-repeat;background-position:50% 50%}
	.campl-fwd-btn{right:10px;left:auto;background-image:url(../assets/icon-fwd-btn-6be0e549306bcafcde64ca8eb6e840ac.png) }
	.campl-back-btn{left:25px;background-image:url(../assets/icon-back-btn-d47a08c0d79d3b6a75b549c08fa92dd2.png)}
	
	.js .campl-menu-btn span{padding:15px 0 15px 25px;display:block}
	.js .campl-menu-btn a{padding:0;position:relative;}
	.js .campl-menu-btn .campl-menu-btn-arrow{width:53px;height:53px;display:block;position:absolute;top:0;right:0;padding:0;background-repeat:no-repeat;background-position:50% 50%}

	.js .campl-local-navigation .campl-local-navigation-container{position:absolute;left:-9999px;}
	.js .campl-local-navigation ul{margin:0;width:480px;position:absolute;}
	.js .campl-local-navigation li{float:none;}
	.js .campl-local-navigation li.campl-title{display:block}
	.js .campl-local-navigation li.campl-title, .js .campl-local-navigation li.campl-current-page > a{font-weight:bold}
	.js .campl-local-navigation li.campl-title a{cursor:default}
	.js .campl-local-navigation li.campl-current-page > a{background-image:url(../assets/icon-fwd-btn-6be0e549306bcafcde64ca8eb6e840ac.png);background-position: 25px 50%;background-repeat: no-repeat;padding-left:40px }
	
	.js .campl-local-navigation ul li ul{left:480px;top:0;padding-top:0}

	.js .campl-local-navigation ul li.campl-current>ul,
	.js .campl-local-navigation ul li.campl-current ul li.campl-current>ul,
	.js .campl-local-navigation ul li.campl-current ul li.campl-current ul li.campl-current>ul,
	.js .campl-local-navigation ul li.campl-current ul li.campl-current ul li.campl-current ul li.campl-current>ul,
	.js .campl-local-navigation ul li.campl-current ul li.campl-current ul li.campl-current ul li.campl-current ul li.campl-current>ul,
	.js .campl-local-navigation ul li.campl-current ul li.campl-current ul li.campl-current ul li.campl-current ul li.campl-current ul li.campl-current>ul{display:block}

	.js .campl-local-navigation ul li ul,
	.js .campl-local-navigation ul li.campl-current ul li ul,
	.js .campl-local-navigation ul li.campl-current ul li.campl-current ul li ul,
	.js .campl-local-navigation ul li.campl-current ul li.campl-current ul li.campl-current ul li ul,
	.js .campl-local-navigation ul li.campl-current ul li.campl-current ul li.campl-current ul li.campl-current ul li ul,
	.js .campl-local-navigation ul li.campl-current ul li.campl-current ul li.campl-current ul li.campl-current ul li.campl-current ul li ul{display:none}

	/* styles for sub and title links */
	.js .campl-local-navigation li a{display:block;color:#fff;text-decoration:none;}
	.js .campl-local-navigation-container li.campl-back-link a{position:relative;padding: 15px 30px 15px 50px;}

	.campl-co-branding-logo{float:none}

	.campl-open-menu{margin: 15px;}
	
	.campl-breadcrumb{display:none}
	.campl-mobile-parent{display:block;}
	.campl-section-page .campl-mobile-parent, .campl-sub-section-page .campl-mobile-parent{display:none}
	
	.campl-listing-txt{padding:20px 0 0 }
	.campl-listing-img{padding:0 0 10px}
	
	.campl-event-details-dl dt, .campl-event-details-dl dd, .campl-event-details-dl{float:none}
	.campl-event-details, .campl-column6 .campl-event-details{background-image:none}
	
	.campl-current-date{padding:7px 0 8px 0}
	
	.campl-slide-caption{opacity:1;position:static;}

	.campl-carousel{overflow:hidden;background:#000}
	.campl-carousel-controls{position:absolute;top:0;bottom:auto;left:0;right:auto;width:100%}
	.campl-carousel-controls li{margin:0;width:50px;float:none;height:auto;position:absolute;top:0;}
	.campl-slide-caption{background:#000}
	.campl-carousel-control-btn{overflow:auto;width:auto;opacity:0.8}
	
	.campl-carousel-content{text-align:left}
	
	.campl-next-li{right:0;left:auto;}

	.campl-event-cta-container{padding:30px 20px 0 20px}
	.campl-events-cta {float:none}
	.campl-events-cta li{margin-left:0;float:none;margin-bottom:30px}

	.campl-table th, .campl-table td {line-height: 24px}

	/* Force table to not be like tables anymore */
	.campl-vertical-stacking-table table, .campl-vertical-stacking-table thead, .campl-vertical-stacking-table tbody, .campl-vertical-stacking-table th, .campl-vertical-stacking-table td, .campl-vertical-stacking-table tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	.campl-vertical-stacking-table thead tr { position: absolute;top: -9999px;left: -9999px;}
	
	.campl-vertical-stacking-table tr { border: 1px solid #ccc; }
	
	/* Behave  like a "row" */
	.campl-vertical-stacking-table td {	border: none;border-bottom: 1px solid #e4e4e4; }
	.campl-vertical-stacking-table td:before { white-space: normal;}
	.campl-table-heading{display:block;}
	
	.campl-nav-tabs{padding-left:0}

	.campl-column3 .Zebra_DatePicker table{font-size:16px}
	.campl-column3 .Zebra_DatePicker .dp_daypicker td{ padding: 0 3px 9px 6px;}
	
	.campl-overlay{right:auto;left:0}
	
	.campl-mobile-list-layout{display:block}
	.campl-desktop-list-layout{display:none}
	
	.campl-notifications-container{padding:10px 20px}
	.campl-notifications-icon {float:none}
	.campl-close-panel{top:10px;right:20px}
	
	.campl-section-list li li{padding: 0 0 10px}
	.campl-section-list li li li{padding: 5px 0 5px}

}
	
/* iphone landscape */	
@media (max-width: 480px) {}
	
	
/* 9.0 COLOUR THEMES ------------------------------------------------------------------------------------------------*/
/* COLOUR LEGEND */

/* Default - turquoise */
.campl-page-header, 
.campl-local-footer{background:#106470}
.campl-local-footer, .campl-local-footer h3 a{color:#91b9a4;}
.campl-page-sub-title, 
.campl-banner-content{background:#1e7680}
.campl-tertiary-navigation{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAM0lEQVQYV2NkQAM3P3/7r87LxYgsDBLDEMCmCCQGV4jLJJhGsEJCikBqGIlRBFY4nDwDAIeHT431w+WIAAAAAElFTkSuQmCC);min-height:100%}

.campl-carousel-control-btn{background:#28828a}
.campl-carousel-control-btn:hover{background:#0c5963}

.campl-homepage-carousel .campl-slide{background:#28828a;color:#fff}
.campl-partnership-branding{border-color:#28828a}

.campl-highlight-date{background:#28828a}
.campl-focus-teaser{background:#106470;}
.campl-focus-link{background-color:#0c5963}
.campl-main-content h1, 
.campl-main-content h2 {color:#106470 }

table, 
.campl-table-bordered {border-bottom:2px solid #28828a;}
th{background:#28828a;color:#fff}
th.campl-alt{background:#fff;color:#28828a}
.campl-table-striped tbody tr:nth-child(odd) td,
.campl-table-striped tbody tr:nth-child(odd) th { background-color: #d2f3e1;}
.campl-table-bordered th{border-left: 1px solid #d2f3e1;}


/* local navigation */
.campl-local-navigation {background:#0c5963;border-bottom:1px solid #106470;border-top:1px solid #106470}
.campl-local-navigation a{background:#0c5963;border-right:1px solid #106470;border-bottom:1px solid #106470;border-top:1px solid #106470}
.campl-local-navigation a:focus, 
.campl-local-navigation a:hover, 
.campl-local-navigation a:active{background-color:#1e7680;}
.campl-local-navigation a.campl-selected{background:#28828a}
.campl-local-navigation li.campl-hover a{background-color:#003a41;border-bottom:0}
.campl-local-navigation li.campl-sub li a{background-color: #003a41;border-right:0; }
.campl-local-navigation li.campl-sub li li a{border-bottom:1px solid #0c5963;}
.campl-local-navigation li.campl-sub li:last-child a {border-bottom:0;}

.js .campl-local-navigation li.campl-sub>a:focus, 
.js .campl-local-navigation li.campl-sub>a:hover, 
.js .campl-local-navigation li.campl-sub>a:active{background-color:#0c5963;}

.js .campl-local-navigation li.campl-sub a:focus, 
.js .campl-local-navigation li.campl-sub a:hover{background-color: #003a41}
.js .campl-local-navigation li.campl-sub li a:focus, 
.js .campl-local-navigation li.campl-sub li a:hover{background-color: #0c5963;}


/* Theme 1 - blue 
 	#002e55 dark highlight
	#003e74 page header colours/global footer
	#004e8f carousel btn hover
	#004e8f main local nav bar
	#005dab	lighter highlight
	#0072cf selected state/carousel text bg
	#68ace5 pale tone - lightest highlight/footer headings
	#d2e4f3 pale table bg
*/
/* Theme 1 - uom purple - Pete Morris
	#6b2c91 selected state/carousel text bg
*/

.campl-theme-1 .campl-page-header, 
.campl-theme-1 .campl-local-footer{background:#003e74}
.campl-theme-1 .campl-local-footer, .campl-theme-1 .campl-local-footer h3 a{color:#68ace5;}
.campl-theme-1 .campl-page-sub-title, 
.campl-theme-1 .campl-banner-content{background:#005dab}
.campl-theme-1 .campl-tertiary-navigation{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAM0lEQVQYV2NkQAM3X/34ry7GwYgsDBLDEMCmCCQGV4jLJJhGsEJCikBqGIlRBFY4nDwDAHbOT3++G7jxAAAAAElFTkSuQmCC);min-height:100%}

.campl-theme-1 .campl-carousel-control-btn{background:#6b2c91}
.campl-theme-1 .campl-carousel-control-btn:hover{background:#004e8f}

.campl-theme-1 .campl-homepage-carousel .campl-slide{background:#6b2c91;color:#fff}
.campl-theme-1 .campl-partnership-branding{border-color:#6b2c91}

.campl-theme-1 .campl-highlight-date{background:#6b2c91}
.campl-theme-1 .campl-focus-teaser{background:#003e74;}
.campl-theme-1 .campl-focus-link{background-color:#004e8f}
.campl-theme-1 .campl-main-content h1, 
.campl-theme-1 .campl-main-content h2 {color:#003e74 }

.campl-theme-1 table, 
.campl-theme-1 .campl-table-bordered {border-bottom:2px solid #6b2c91;}
.campl-theme-1 th{background:#6b2c91;color:#fff}
.campl-theme-1 th.campl-alt{background:#fff;color:#6b2c91}
.campl-theme-1 .campl-table-striped tbody tr:nth-child(odd) td,
.campl-theme-1 .campl-table-striped tbody tr:nth-child(odd) th { background-color: #d2e4f3;}
.campl-theme-1 .campl-table-bordered th{border-left: 1px solid #d2e4f3;}


/* local navigation */
.campl-theme-1 .campl-local-navigation {background:#004e8f;border-bottom:1px solid #003e74;border-top:1px solid #003e74}
.campl-theme-1 .campl-local-navigation a{background:#004e8f;border-right:1px solid #003e74;border-bottom:1px solid #003e74;border-top:1px solid #003e74}
.campl-theme-1 .campl-local-navigation a:focus, 
.campl-theme-1 .campl-local-navigation a:hover, 
.campl-theme-1 .campl-local-navigation a:active{background-color:#005dab;}
.campl-theme-1 .campl-local-navigation a.campl-selected{background:#6b2c91 }
.campl-theme-1 .campl-local-navigation li.campl-hover a{background-color:#002e55}
.campl-theme-1 .campl-local-navigation li.campl-sub li a{background-color: #002e55;border-right:0; }
.campl-theme-1 .campl-local-navigation li.campl-sub li li a{border-bottom:1px solid #004e8f;}
.campl-theme-1 .campl-local-navigation li.campl-sub li:last-child a {border-bottom:0;}

.js .campl-theme-1 .campl-local-navigation li.campl-sub>a:focus, 
.js .campl-theme-1 .campl-local-navigation li.campl-sub>a:hover, 
.js .campl-theme-1 .campl-local-navigation li.campl-sub>a:active{background-color:#004e8f;}

.js .campl-theme-1 .campl-local-navigation li.campl-sub a:focus, 
.js .campl-theme-1 .campl-local-navigation li.campl-sub a:hover{background-color: #002e55}
.js .campl-theme-1 .campl-local-navigation li.campl-sub li a:focus, 
.js .campl-theme-1 .campl-local-navigation li.campl-sub li a:hover{background-color: #004e8f;}


/* Theme 2 - turquoise
  	#003a41	dark highlight
	#106470 page header colours/global footer
	#0c5963	carousel btn hover
	#0c5963 main local nav bar
	#1e7680 lighter highlight
	#28828a selected state/carousel text bg
	#91b9a4 pale tone - lightest highlight/footer headings
	#d2f3e1 pale - table bg
*/	
.campl-theme-2 .campl-page-header, 
.campl-theme-2 .campl-local-footer{background:#106470}
.campl-theme-2 .campl-local-footer, .campl-theme-2 .campl-local-footer h3 a{color:#91b9a4;}
.campl-theme-2 .campl-page-sub-title, 
.campl-theme-2 .campl-banner-content{background:#1e7680}
.campl-theme-2 .campl-tertiary-navigation{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAM0lEQVQYV2NkQAM3P3/7r87LxYgsDBLDEMCmCCQGV4jLJJhGsEJCikBqGIlRBFY4nDwDAIeHT431w+WIAAAAAElFTkSuQmCC);min-height:100%}

.campl-theme-2 .campl-carousel-control-btn{background:#28828a}
.campl-theme-2 .campl-carousel-control-btn:hover{background:#0c5963}

.campl-theme-2 .campl-homepage-carousel .campl-slide{background:#28828a;color:#fff}
.campl-theme-2 .campl-partnership-branding{border-color:#28828a}

.campl-theme-2 .campl-highlight-date{background:#28828a}
.campl-theme-2 .campl-focus-teaser{background:#106470;}
.campl-theme-2 .campl-focus-link{background-color:#0c5963}
.campl-theme-2 .campl-main-content h1, 
.campl-theme-2 .campl-main-content h2 {color:#106470 }

.campl-theme-2 table, 
.campl-theme-2 .campl-table-bordered {border-bottom:2px solid #28828a;}
.campl-theme-2 th{background:#28828a;color:#fff}
.campl-theme-2 th.campl-alt{background:#fff;color:#28828a}
.campl-theme-2 .campl-table-striped tbody tr:nth-child(odd) td,
.campl-theme-2 .campl-table-striped tbody tr:nth-child(odd) th { background-color: #d2f3e1;}
.campl-theme-2 .campl-table-bordered th{border-left: 1px solid #d2f3e1;}


/* local navigation */
.campl-theme-2 .campl-local-navigation {background:#0c5963;border-bottom:1px solid #106470;border-top:1px solid #106470}
.campl-theme-2 .campl-local-navigation a{background:#0c5963;border-right:1px solid #106470;border-bottom:1px solid #106470;border-top:1px solid #106470}
.campl-theme-2 .campl-local-navigation a:focus, 
.campl-theme-2 .campl-local-navigation a:hover, 
.campl-theme-2 .campl-local-navigation a:active{background-color:#1e7680;}
.campl-theme-2 .campl-local-navigation a.campl-selected{background:#28828a}
.campl-theme-2 .campl-local-navigation li.campl-hover a{background-color:#003a41}
.campl-theme-2 .campl-local-navigation li.campl-sub li a{background-color: #003a41;border-right:0; }
.campl-theme-2 .campl-local-navigation li.campl-sub li li a{border-bottom:1px solid #0c5963;}
.campl-theme-2 .campl-local-navigation li.campl-sub li:last-child a {border-bottom:0;}

.js .campl-theme-2 .campl-local-navigation li.campl-sub>a:focus, 
.js .campl-theme-2 .campl-local-navigation li.campl-sub>a:hover, 
.js .campl-theme-2 .campl-local-navigation li.campl-sub>a:active{background-color:#0c5963;}

.js .campl-theme-2 .campl-local-navigation li.campl-sub a:focus, 
.js .campl-theme-2 .campl-local-navigation li.campl-sub a:hover{background-color: #003a41}
.js .campl-theme-2 .campl-local-navigation li.campl-sub li a:focus, 
.js .campl-theme-2 .campl-local-navigation li.campl-sub li a:hover{background-color: #0c5963;}


/* Theme 3 - purple
 	#35254a dark highlight
	#422e5d page header colours/global footer
	#6a2068	carousel btn hover - should i change this?
	#612d70 main local nav bar
	#782c7e lighter highlight
	#8f2b8c selected state/carousel text bg
	#af95a3 pale tone - lightest highlight/footer headings
	#f4d3e5 pale table bg
 */
.campl-theme-3 .campl-page-header, 
.campl-theme-3 .campl-local-footer{background:#422e5d}
.campl-theme-3 .campl-local-footer, .campl-theme-3 .campl-local-footer h3 a{color:#af95a3;}
.campl-theme-3 .campl-page-sub-title, 
.campl-theme-3 .campl-banner-content{background:#782c7e}
.campl-theme-3 .campl-tertiary-navigation{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIklEQVQIW2NkQALv7r/7zwjjgzhCikKMYAEYB8RmROaABAAPAg+/3tdmjQAAAABJRU5ErkJggg==);min-height:100%}

.campl-theme-3 .campl-carousel-control-btn{background:#8f2b8c}
.campl-theme-3 .campl-carousel-control-btn:hover{background:#6a2068}

.campl-theme-3 .campl-homepage-carousel .campl-slide{background:#8f2b8c;color:#fff}
.campl-theme-3 .campl-partnership-branding{border-color:#8f2b8c}

.campl-theme-3 .campl-highlight-date{background:#8f2b8c}
.campl-theme-3 .campl-focus-teaser{background:#422e5d;}
.campl-theme-3 .campl-focus-link{background-color:#612d70}
.campl-theme-3 .campl-main-content h1, 
.campl-theme-3 .campl-main-content h2 {color:#422e5d }

.campl-theme-3 table, 
.campl-theme-3 .campl-table-bordered {border-bottom:2px solid #8f2b8c;}
.campl-theme-3 th{background:#8f2b8c;color:#fff}
.campl-theme-3 th.campl-alt{background:#fff;color:#8f2b8c}
.campl-theme-3 .campl-table-striped tbody tr:nth-child(odd) td,
.campl-theme-3 .campl-table-striped tbody tr:nth-child(odd) th { background-color: #f4d3e5;}
.campl-theme-3 .campl-table-bordered th{border-left: 1px solid #f4d3e5;}


/* local navigation */
.campl-theme-3 .campl-local-navigation {background:#612d70;border-bottom:1px solid #422E5D;border-top:1px solid #422E5D}
.campl-theme-3 .campl-local-navigation a{background:#612d70;border-right:1px solid #422E5D;border-bottom:1px solid #422E5D;border-top:1px solid #422E5D}
.campl-theme-3 .campl-local-navigation a:focus, 
.campl-theme-3 .campl-local-navigation a:hover, 
.campl-theme-3 .campl-local-navigation a:active{background-color:#782c7e;}
.campl-theme-3 .campl-local-navigation a.campl-selected{background:#8f2b8c }
.campl-theme-3 .campl-local-navigation li.campl-hover a{background-color:#35254a}
.campl-theme-3 .campl-local-navigation li.campl-sub li a{background-color: #35254a;border-right:0; }
.campl-theme-3 .campl-local-navigation li.campl-sub li li a{border-bottom:1px solid #612d70;}
.campl-theme-3 .campl-local-navigation li.campl-sub li:last-child a {border-bottom:0;}

.js .campl-theme-3 .campl-local-navigation li.campl-sub>a:focus, 
.js .campl-theme-3 .campl-local-navigation li.campl-sub>a:hover, 
.js .campl-theme-3 .campl-local-navigation li.campl-sub>a:active{background-color:#612d70;}

.js .campl-theme-3 .campl-local-navigation li.campl-sub a:focus, 
.js .campl-theme-3 .campl-local-navigation li.campl-sub a:hover{background-color: #35254a}
.js .campl-theme-3 .campl-local-navigation li.campl-sub li a:focus, 
.js .campl-theme-3 .campl-local-navigation li.campl-sub li a:hover{background-color: #612d70;}


/* Theme 4 - green 
 	#222f16 dark highlight
	#304220 page header colours/global footer
	#355918	carousel btn hover
	#355918	main local nav bar
	#4b7016 lighter highlight
	#57831a selected state
	#aab300 pale tone - lightest highlight
	#f4f6cd pale table bg
 */
.campl-theme-4 .campl-page-header, 
.campl-theme-4 .campl-local-footer{background:#304220}
.campl-theme-4 .campl-local-footer, .campl-theme-4 .campl-local-footer h3 a{color:#aab300;}
.campl-theme-4 .campl-page-sub-title, 
.campl-theme-4 .campl-banner-content{background:#4b701c}
.campl-theme-4 .campl-tertiary-navigation{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAM0lEQVQYV2NkQAPPPt39L8WnzIgsDBLDEMCmCCQGV4jLJJhGsEJCikBqGIlRBFY4nDwDAGjMT3NzIr6SAAAAAElFTkSuQmCC);min-height:100%}

.campl-theme-4 .campl-carousel-control-btn{background:#57831a}
.campl-theme-4 .campl-carousel-control-btn:hover{background:#355918}

.campl-theme-4 .campl-homepage-carousel .campl-slide{background:#57831a;color:#fff}
.campl-theme-4 .campl-partnership-branding{border-color:#57831a}

.campl-theme-4 .campl-highlight-date{background:#57831a}
.campl-theme-4 .campl-focus-teaser{background:#304220;}
.campl-theme-4 .campl-focus-link{background-color:#355918}
.campl-theme-4 .campl-main-content h1, 
.campl-theme-4 .campl-main-content h2 {color:#304220 }

.campl-theme-4 table, 
.campl-theme-4 .campl-table-bordered {border-bottom:2px solid #57831a;}
.campl-theme-4 th{background:#57831a;color:#fff}
.campl-theme-4 th.campl-alt{background:#fff;color:#57831a}
.campl-theme-4 .campl-table-striped tbody tr:nth-child(odd) td,
.campl-theme-4 .campl-table-striped tbody tr:nth-child(odd) th { background-color: #f4f6cd;}
.campl-theme-4 .campl-table-bordered th{border-left: 1px solid #f4f6cd;}

/* local navigation */
.campl-theme-4 .campl-local-navigation {background:#355918;border-bottom:1px solid #304220;border-top:1px solid #304220}
.campl-theme-4 .campl-local-navigation a{background:#355918;border-right:1px solid #304220;border-bottom:1px solid #304220;border-top:1px solid #304220}
.campl-theme-4 .campl-local-navigation a:focus, 
.campl-theme-4 .campl-local-navigation a:hover, 
.campl-theme-4 .campl-local-navigation a:active{background-color:#4b701c;}
.campl-theme-4 .campl-local-navigation a.campl-selected{background:#57831a }
.campl-theme-4 .campl-local-navigation li.campl-hover a{background-color:#222f16}
.campl-theme-4 .campl-local-navigation li.campl-sub li a{background-color: #222f16;border-right:0; }
.campl-theme-4 .campl-local-navigation li.campl-sub li li a{border-bottom:1px solid #355918;}
.campl-theme-4 .campl-local-navigation li.campl-sub li:last-child a {border-bottom:0;}

.js .campl-theme-4 .campl-local-navigation li.campl-sub>a:focus, 
.js .campl-theme-4 .campl-local-navigation li.campl-sub>a:hover, 
.js .campl-theme-4 .campl-local-navigation li.campl-sub>a:active{background-color:#355918;}

.js .campl-theme-4 .campl-local-navigation li.campl-sub a:focus, 
.js .campl-theme-4 .campl-local-navigation li.campl-sub a:hover{background-color: #222f16}
.js .campl-theme-4 .campl-local-navigation li.campl-sub li a:focus, 
.js .campl-theme-4 .campl-local-navigation li.campl-sub li a:hover{background-color: #355918;}


/* Theme 5 - orange
 	#772801 dark highlight
	#c44101 page header colours/global footer
	#ab3901	carousel btn hover
	#ab3901 main local nav bar
	#d45812 lighter highlight
	#df671d selected state
	#f3bd48 pale tone - lightest highlight
	#f5e7ca pale table bg
 */
.campl-theme-5 .campl-page-header, 
.campl-theme-5 .campl-local-footer{background:#c44101}
.campl-theme-5 .campl-local-footer, .campl-theme-5 .campl-local-footer h3 a{color:#f3bd48;}
.campl-theme-5 .campl-page-sub-title, 
.campl-theme-5 .campl-banner-content{background:#d45812}
.campl-theme-5 .campl-tertiary-navigation{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAM0lEQVQYV2NkQAN/Xt3/zyKmyIgsDBLDEMCmCCQGV4jLJJhGsEJCikBqGIlRBFY4nDwDAI8kT5P7ti8QAAAAAElFTkSuQmCC);min-height:100%}

.campl-theme-5 .campl-carousel-control-btn{background:#df671d}
.campl-theme-5 .campl-carousel-control-btn:hover{background:#ab3901}

.campl-theme-5 .campl-homepage-carousel .campl-slide{background:#df671d;color:#fff}
.campl-theme-5 .campl-partnership-branding{border-color:#df671d}

.campl-theme-5 .campl-highlight-date{background:#df671d}
.campl-theme-5 .campl-focus-teaser{background:#c44101;}
.campl-theme-5 .campl-focus-link{background-color:#ab3901}
.campl-theme-5 .campl-main-content h1, 
.campl-theme-5 .campl-main-content h2 {color:#c44101 }

.campl-theme-5 table, 
.campl-theme-5 .campl-table-bordered {border-bottom:2px solid #df671d;}
.campl-theme-5 th{background:#df671d;color:#fff}
.campl-theme-5 th.campl-alt{background:#fff;color:#df671d}
.campl-theme-5 .campl-table-striped tbody tr:nth-child(odd) td,
.campl-theme-5 .campl-table-striped tbody tr:nth-child(odd) th { background-color: #f5e7ca;}
.campl-theme-5 .campl-table-bordered th{border-left: 1px solid #f5e7ca;}


/* local navigation */
.campl-theme-5 .campl-local-navigation {background:#ab3901;border-bottom:1px solid #c44101;border-top:1px solid #c44101}
.campl-theme-5 .campl-local-navigation a{background:#ab3901;border-right:1px solid #c44101;border-bottom:1px solid #c44101;border-top:1px solid #c44101}
.campl-theme-5 .campl-local-navigation a:focus, 
.campl-theme-5 .campl-local-navigation a:hover, 
.campl-theme-5 .campl-local-navigation a:active{background-color:#d45812;}
.campl-theme-5 .campl-local-navigation a.campl-selected{background:#df671d }
.campl-theme-5 .campl-local-navigation li.campl-hover a{background-color:#772801}
.campl-theme-5 .campl-local-navigation li.campl-sub li a{background-color: #772801;border-right:0; }
.campl-theme-5 .campl-local-navigation li.campl-sub li li a{border-bottom:1px solid #ab3901;}
.campl-theme-5 .campl-local-navigation li.campl-sub li:last-child a {border-bottom:0;}

.js .campl-theme-5 .campl-local-navigation li.campl-sub>a:focus, 
.js .campl-theme-5 .campl-local-navigation li.campl-sub>a:hover, 
.js .campl-theme-5 .campl-local-navigation li.campl-sub>a:active{background-color:#ab3901;}

.js .campl-theme-5 .campl-local-navigation li.campl-sub a:focus, 
.js .campl-theme-5 .campl-local-navigation li.campl-sub a:hover{background-color: #772801}
.js .campl-theme-5 .campl-local-navigation li.campl-sub li a:focus, 
.js .campl-theme-5 .campl-local-navigation li.campl-sub li a:hover{background-color: #ab3901;}


/* Theme 6 - red 
 	#6c112a	dark highlight
	#851735 page header colours/global footer
	#a51137 carousel btn hover
	#a51137 main local nav bar
	#be1741 lighter highlight
	#d61746 selected state
	#eb99a9 pale tone - lightest highlight
	#f8e1e5 pale table bg
 */
.campl-theme-6 .campl-page-header, 
.campl-theme-6 .campl-local-footer{background:#851735}
.campl-theme-6 .campl-local-footer, .campl-theme-6 .campl-local-footer h3 a{color:#eb99a9;}
.campl-theme-6 .campl-page-sub-title, 
.campl-theme-6 .campl-banner-content{background:#be1741}
.campl-theme-6 .campl-tertiary-navigation{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAM0lEQVQYV2NkQAM/bz36z64mx4gsDBLDEMCmCCQGV4jLJJhGsEJCikBqGIlRBFY4nDwDAGjoT3MabQ+4AAAAAElFTkSuQmCC);min-height:100%}

.campl-theme-6 .campl-carousel-control-btn{background:#d61746}
.campl-theme-6 .campl-carousel-control-btn:hover{background:#a51137}

.campl-theme-6 .campl-homepage-carousel .campl-slide{background:#d61746;color:#fff}
.campl-theme-6 .campl-partnership-branding{border-color:#d61746}

.campl-theme-6 .campl-highlight-date{background:#d61746}
.campl-theme-6 .campl-focus-teaser{background:#851735;}
.campl-theme-6 .campl-focus-link{background-color:#a51137}
.campl-theme-6 .campl-main-content h1, 
.campl-theme-6 .campl-main-content h2 {color:#851735 }

.campl-theme-6 table, 
.campl-theme-6 .campl-table-bordered {border-bottom:2px solid #d61746;}
.campl-theme-6 th{background:#d61746;color:#fff}
.campl-theme-6 th.campl-alt{background:#fff;color:#d61746}
.campl-theme-6 .campl-table-striped tbody tr:nth-child(odd) td,
.campl-theme-6 .campl-table-striped tbody tr:nth-child(odd) th { background-color: #f8e1e5;}
.campl-theme-6 .campl-table-bordered th{border-left: 1px solid #f8e1e5;}

/* local navigation */
.campl-theme-6 .campl-local-navigation {background:#a51137;border-bottom:1px solid #851735;border-top:1px solid #851735}
.campl-theme-6 .campl-local-navigation a{background:#a51137;border-right:1px solid #851735;border-bottom:1px solid #851735;border-top:1px solid #851735}
.campl-theme-6 .campl-local-navigation a:focus, 
.campl-theme-6 .campl-local-navigation a:hover, 
.campl-theme-6 .campl-local-navigation a:active{background-color:#be1741;}
.campl-theme-6 .campl-local-navigation a.campl-selected{background:#d61746}
.campl-theme-6 .campl-local-navigation li.campl-hover a{background-color:#6c112a}
.campl-theme-6 .campl-local-navigation li.campl-sub li a{background-color: #6c112a;border-right:0; }
.campl-theme-6 .campl-local-navigation li.campl-sub li li a{border-bottom:1px solid #a51137;}
.campl-theme-6 .campl-local-navigation li.campl-sub li:last-child a {border-bottom:0;}

.js .campl-theme-6 .campl-local-navigation li.campl-sub>a:focus, 
.js .campl-theme-6 .campl-local-navigation li.campl-sub>a:hover, 
.js .campl-theme-6 .campl-local-navigation li.campl-sub>a:active{background-color:#a51137;}

.js .campl-theme-6 .campl-local-navigation li.campl-sub a:focus, 
.js .campl-theme-6 .campl-local-navigation li.campl-sub a:hover{background-color: #6c112a}
.js .campl-theme-6 .campl-local-navigation li.campl-sub li a:focus, 
.js .campl-theme-6 .campl-local-navigation li.campl-sub li a:hover{background-color: #a51137;}

/* Theme 7 - grey 
	#2F2F2F	dark highlight
	#404040 page header colours/global footer
	#5F5F5F carousel btn hover
	#5F5F5F main local nav bar
	#7d7777 lighter highlight
	#8C8989 selected state
	#EAE8E8 pale tone - lightest highlight
	#DBD9D9 pale table bg
 */
.campl-theme-7 .campl-page-header, 
.campl-theme-7 .campl-local-footer{background:#404040}
.campl-theme-7 .campl-local-footer, .campl-theme-7 .campl-local-footer h3 a{color:#EAE8E8;}
.campl-theme-7 .campl-page-sub-title, 
.campl-theme-7 .campl-banner-content{background:#7d7777}
.campl-theme-7 .campl-tertiary-navigation{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAHElEQVQYlWNgQAOfP3/+T1BsMCrCBgajO4n1DACTJUzhVcNWTAAAAABJRU5ErkJggg);min-height:100%}

.campl-theme-7 .campl-carousel-control-btn{background:#8C8989}
.campl-theme-7 .campl-carousel-control-btn:hover{background:#5F5F5F}

.campl-theme-7 .campl-homepage-carousel .campl-slide{background:#8C8989;color:#fff}
.campl-theme-7 .campl-partnership-branding{border-color:#8C8989}

.campl-theme-7 .campl-highlight-date{background:#8C8989}
.campl-theme-7 .campl-focus-teaser{background:#404040;}
.campl-theme-7 .campl-focus-link{background-color:#5F5F5F}
.campl-theme-7 .campl-main-content h1, 
.campl-theme-7 .campl-main-content h2 {color:#404040 }

.campl-theme-7 table, 
.campl-theme-7 .campl-table-bordered {border-bottom:2px solid #8C8989;}
.campl-theme-7 th{background:#8C8989;color:#fff}
.campl-theme-7 th.campl-alt{background:#fff;color:#8C8989}
.campl-theme-7 .campl-table-striped tbody tr:nth-child(odd) td,
.campl-theme-7 .campl-table-striped tbody tr:nth-child(odd) th { background-color: #DBD9D9;}
.campl-theme-7 .campl-table-bordered th{border-left: 1px solid #DBD9D9;}

/* local navigation */
.campl-theme-7 .campl-local-navigation {background:#5F5F5F;border-bottom:1px solid #404040;border-top:1px solid #404040}
.campl-theme-7 .campl-local-navigation a{background:#5F5F5F;border-right:1px solid #404040;border-bottom:1px solid #404040;border-top:1px solid #404040}
.campl-theme-7 .campl-local-navigation a:focus, 
.campl-theme-7 .campl-local-navigation a:hover, 
.campl-theme-7 .campl-local-navigation a:active{background-color:#7d7777;}
.campl-theme-7 .campl-local-navigation a.campl-selected{background:#8C8989}
.campl-theme-7 .campl-local-navigation li.campl-hover a{background-color:#2F2F2F}
.campl-theme-7 .campl-local-navigation li.campl-sub li a{background-color: #2F2F2F;border-right:0; }
.campl-theme-7 .campl-local-navigation li.campl-sub li li a{border-bottom:1px solid #5F5F5F;}
.campl-theme-7 .campl-local-navigation li.campl-sub li:last-child a {border-bottom:0;}

.js .campl-theme-7 .campl-local-navigation li.campl-sub>a:focus, 
.js .campl-theme-7 .campl-local-navigation li.campl-sub>a:hover, 
.js .campl-theme-7 .campl-local-navigation li.campl-sub>a:active{background-color:#5F5F5F;}

.js .campl-theme-7 .campl-local-navigation li.campl-sub a:focus, 
.js .campl-theme-7 .campl-local-navigation li.campl-sub a:hover{background-color: #2F2F2F}
.js .campl-theme-7 .campl-local-navigation li.campl-sub li a:focus, 
.js .campl-theme-7 .campl-local-navigation li.campl-sub li a:hover{background-color: #5F5F5F;}





/* styles for menu opening navigation - ipad portrait and lower */
@media only screen and (max-width:767px){
	
	/* generic mobile local nav styles */
	.campl-local-navigation{border:0}
	.js .campl-closed .campl-menu-btn-arrow{background-image:url(../assets/icon-close-btn-312617e75f8ef1350f4eec21651c3a8c.png)}	
	.js .campl-open .campl-menu-btn-arrow{background-image:url(../assets/icon-open-btn-61da39e3c4c80ff6a2ddf0e46cf3bd10.png)}
	.js .campl-menu-btn a:focus, .js .campl-menu-btn a:hover{border:0;}
	.campl-local-navigation ul{border-top:0;}
	
	/* Default - turquoise */
	.js .campl-local-navigation a{background:#106470;border-right:0;border-top:0}
	.campl-local-navigation li a{border-bottom:1px solid #0c5963;}
	.js .campl-local-navigation li li a,
	.js .campl-local-navigation li.campl-sub li.campl-sub a{background-color:#106470;}
	
	.js .campl-local-navigation li.campl-title a,
	.js .campl-local-navigation li.campl-sub li.campl-title a,
	.js .campl-local-navigation li.campl-sub li.campl-sub li.campl-title a,
	.js .campl-local-navigation a:focus,
	.js .campl-local-navigation a:hover,
	.js .campl-local-navigation a:active{background-color:#0c5963;}
	
	.js .campl-local-navigation li.campl-sub a:focus, 
	.js .campl-local-navigation li.campl-sub a:hover{background-color: #0c5963}
	.js .campl-local-navigation li.campl-sub li a:focus, 
	.js .campl-local-navigation li.campl-sub li a:hover{background-color: #0c5963;}

	.js .campl-menu-btn a{border:0;background:#0c5963;}
	
	.campl-menu-btn-arrow{background: #003a41 }
	.js .campl-menu-btn-arrow a:focus, 
	.js .campl-menu-btn-arrow a:hover{background-color:#003a41;}
	.campl-menu-indicator{background-color:#0c5963;}

	.js .campl-local-navigation-container li:first-child a{border-top:1px solid #003a41;}
	
	.js .campl-local-navigation-container li.campl-back-link a,
	.js .campl-local-navigation-container li.campl-sub li.campl-back-link a,
	.js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a,
	.js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a {border-bottom:1px solid #0c5963; background-color: #003a41;}
	
	.js .campl-local-navigation-container li.campl-back-link a:focus,
	.js .campl-local-navigation-container li.campl-back-link a:hover,
	.js .campl-local-navigation-container li.campl-sub li.campl-back-link a:focus,
	.js .campl-local-navigation-container li.campl-sub li.campl-back-link a:hover,
	.js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:hover,
	.js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:hover{background-color:#0c5963;}
	
	
	/*theme 1*/
	.js .campl-theme-1 .campl-local-navigation a{background:#003e74;border-right:0;border-top:0}
	.campl-theme-1 .campl-local-navigation li a{border-bottom:1px solid #004e8f;}
	.js .campl-theme-1 .campl-local-navigation li li a,
	.js .campl-theme-1 .campl-local-navigation li.campl-sub li.campl-sub a{background-color:#003e74;}
	
	.js .campl-theme-1 .campl-local-navigation li.campl-title a,
	.js .campl-theme-1 .campl-local-navigation li.campl-sub li.campl-title a,
	.js .campl-theme-1 .campl-local-navigation li.campl-sub li.campl-sub li.campl-title a,
	.js .campl-theme-1 .campl-local-navigation a:focus,
	.js .campl-theme-1 .campl-local-navigation a:hover,
	.js .campl-theme-1 .campl-local-navigation a:active{background-color:#004e8f;}
	
	.js .campl-theme-1 .campl-local-navigation li.campl-sub a:focus, 
	.js .campl-theme-1 .campl-local-navigation li.campl-sub a:hover{background-color: #004e8f}
	.js .campl-theme-1 .campl-local-navigation li.campl-sub li a:focus, 
	.js .campl-theme-1 .campl-local-navigation li.campl-sub li a:hover{background-color: #004e8f;}

	.js .campl-theme-1 .campl-menu-btn a{border:0;background:#004e8f;}
	
	.campl-theme-1 .campl-menu-btn-arrow{background: #002e55 }
	.js .campl-theme-1 .campl-menu-btn-arrow a:focus, 
	.js .campl-theme-1 .campl-menu-btn-arrow a:hover{background-color:#002e55;}
	.campl-theme-1 .campl-menu-indicator{background-color:#004e8f;}

	.js .campl-theme-1 .campl-local-navigation-container li:first-child a{border-top:1px solid #002e55;}
	
	.js .campl-theme-1 .campl-local-navigation-container li.campl-back-link a,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-sub li.campl-back-link a,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a {border-bottom:1px solid #004e8f; background-color: #002e55;}
	
	.js .campl-theme-1 .campl-local-navigation-container li.campl-back-link a:focus,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-back-link a:hover,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-1 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:hover{background-color:#004e8f;}
	
	/*theme 2*/
	.js .campl-theme-2 .campl-local-navigation a{background:#106470;border-right:0;border-top:0}
	.campl-theme-2 .campl-local-navigation li a{border-bottom:1px solid #0c5963;}
	.js .campl-theme-2 .campl-local-navigation li li a,
	.js .campl-theme-2 .campl-local-navigation li.campl-sub li.campl-sub a{background-color:#106470;}
	
	.js .campl-theme-2 .campl-local-navigation li.campl-title a,
	.js .campl-theme-2 .campl-local-navigation li.campl-sub li.campl-title a,
	.js .campl-theme-2 .campl-local-navigation li.campl-sub li.campl-sub li.campl-title a,
	.js .campl-theme-2 .campl-local-navigation a:focus,
	.js .campl-theme-2 .campl-local-navigation a:hover,
	.js .campl-theme-2 .campl-local-navigation a:active{background-color:#0c5963;}
	
	.js .campl-theme-2 .campl-local-navigation li.campl-sub a:focus, 
	.js .campl-theme-2 .campl-local-navigation li.campl-sub a:hover{background-color: #0c5963}
	.js .campl-theme-2 .campl-local-navigation li.campl-sub li a:focus, 
	.js .campl-theme-2 .campl-local-navigation li.campl-sub li a:hover{background-color: #0c5963;}

	.js .campl-theme-2 .campl-menu-btn a{border:0;background:#0c5963;}
	
	.campl-theme-2 .campl-menu-btn-arrow{background: #003a41 }
	.js .campl-theme-2 .campl-menu-btn-arrow a:focus, 
	.js .campl-theme-2 .campl-menu-btn-arrow a:hover{background-color:#003a41;}
	.campl-theme-2 .campl-menu-indicator{background-color:#0c5963;}

	.js .campl-theme-2 .campl-local-navigation-container li:first-child a{border-top:1px solid #003a41;}
	
	.js .campl-theme-2 .campl-local-navigation-container li.campl-back-link a,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-sub li.campl-back-link a,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a {border-bottom:1px solid #0c5963; background-color: #003a41;}
	
	.js .campl-theme-2 .campl-local-navigation-container li.campl-back-link a:focus,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-back-link a:hover,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-2 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:hover{background-color:#0c5963;}
	
	/*theme 3*/
	.js .campl-theme-3 .campl-local-navigation a{background:#422E5D;border-right:0;border-top:0}
	.campl-theme-3 .campl-local-navigation li a{border-bottom:1px solid #612d70;}
	.js .campl-theme-3 .campl-local-navigation li li a,
	.js .campl-theme-3 .campl-local-navigation li.campl-sub li.campl-sub a{background-color:#422E5D;}
	
	.js .campl-theme-3 .campl-local-navigation li.campl-title a,
	.js .campl-theme-3 .campl-local-navigation li.campl-sub li.campl-title a,
	.js .campl-theme-3 .campl-local-navigation li.campl-sub li.campl-sub li.campl-title a,
	.js .campl-theme-3 .campl-local-navigation a:focus,
	.js .campl-theme-3 .campl-local-navigation a:hover,
	.js .campl-theme-3 .campl-local-navigation a:active{background-color:#612d70;}
	
	.js .campl-theme-3 .campl-local-navigation li.campl-sub a:focus, 
	.js .campl-theme-3 .campl-local-navigation li.campl-sub a:hover{background-color: #612d70}
	.js .campl-theme-3 .campl-local-navigation li.campl-sub li a:focus, 
	.js .campl-theme-3 .campl-local-navigation li.campl-sub li a:hover{background-color: #612d70;}

	.js .campl-theme-3 .campl-menu-btn a{border:0;background:#612d70;}
	
	.campl-theme-3 .campl-menu-btn-arrow{background: #35254a }
	.js .campl-theme-3 .campl-menu-btn-arrow a:focus, 
	.js .campl-theme-3 .campl-menu-btn-arrow a:hover{background-color:#35254a;}
	.campl-theme-3 .campl-menu-indicator{background-color:#612d70;}

	.js .campl-theme-3 .campl-local-navigation-container li:first-child a{border-top:1px solid #35254a;}
	
	.js .campl-theme-3 .campl-local-navigation-container li.campl-back-link a,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-sub li.campl-back-link a,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a {border-bottom:1px solid #612d70; background-color: #35254a;}
	
	.js .campl-theme-3 .campl-local-navigation-container li.campl-back-link a:focus,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-back-link a:hover,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-3 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:hover{background-color:#612d70;}
	
	/*theme 4*/
	.js .campl-theme-4 .campl-local-navigation a{background:#304220;border-right:0;border-top:0}
	.campl-theme-4 .campl-local-navigation li a{border-bottom:1px solid #355918;}
	.js .campl-theme-4 .campl-local-navigation li li a,
	.js .campl-theme-4 .campl-local-navigation li.campl-sub li.campl-sub a{background-color:#304220;}
	
	.js .campl-theme-4 .campl-local-navigation li.campl-title a,
	.js .campl-theme-4 .campl-local-navigation li.campl-sub li.campl-title a,
	.js .campl-theme-4 .campl-local-navigation li.campl-sub li.campl-sub li.campl-title a,
	.js .campl-theme-4 .campl-local-navigation a:focus,
	.js .campl-theme-4 .campl-local-navigation a:hover,
	.js .campl-theme-4 .campl-local-navigation a:active{background-color:#355918;}
	
	.js .campl-theme-4 .campl-local-navigation li.campl-sub a:focus, 
	.js .campl-theme-4 .campl-local-navigation li.campl-sub a:hover{background-color: #355918}
	.js .campl-theme-4 .campl-local-navigation li.campl-sub li a:focus, 
	.js .campl-theme-4 .campl-local-navigation li.campl-sub li a:hover{background-color: #355918;}

	.js .campl-theme-4 .campl-menu-btn a{border:0;background:#355918;}
	
	.campl-theme-4 .campl-menu-btn-arrow{background: #222f16 }
	.js .campl-theme-4 .campl-menu-btn-arrow a:focus, 
	.js .campl-theme-4 .campl-menu-btn-arrow a:hover{background-color:#222f16;}
	.campl-theme-4 .campl-menu-indicator{background-color:#355918;}

	.js .campl-theme-4 .campl-local-navigation-container li:first-child a{border-top:1px solid #222f16;}
	
	.js .campl-theme-4 .campl-local-navigation-container li.campl-back-link a,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-sub li.campl-back-link a,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a {border-bottom:1px solid #355918; background-color: #222f16;}
	
	.js .campl-theme-4 .campl-local-navigation-container li.campl-back-link a:focus,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-back-link a:hover,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-4 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:hover{background-color:#355918;}
	
	/*theme 5*/
	.js .campl-theme-5 .campl-local-navigation a{background:#c44101;border-right:0;border-top:0}
	.campl-theme-5 .campl-local-navigation li a{border-bottom:1px solid #ab3901;}
	.js .campl-theme-5 .campl-local-navigation li li a,
	.js .campl-theme-5 .campl-local-navigation li.campl-sub li.campl-sub a{background-color:#c44101;}
	
	.js .campl-theme-5 .campl-local-navigation li.campl-title a,
	.js .campl-theme-5 .campl-local-navigation li.campl-sub li.campl-title a,
	.js .campl-theme-5 .campl-local-navigation li.campl-sub li.campl-sub li.campl-title a,
	.js .campl-theme-5 .campl-local-navigation a:focus,
	.js .campl-theme-5 .campl-local-navigation a:hover,
	.js .campl-theme-5 .campl-local-navigation a:active{background-color:#ab3901;}
	
	.js .campl-theme-5 .campl-local-navigation li.campl-sub a:focus, 
	.js .campl-theme-5 .campl-local-navigation li.campl-sub a:hover{background-color: #ab3901}
	.js .campl-theme-5 .campl-local-navigation li.campl-sub li a:focus, 
	.js .campl-theme-5 .campl-local-navigation li.campl-sub li a:hover{background-color: #ab3901;}

	.js .campl-theme-5 .campl-menu-btn a{border:0;background:#ab3901;}
	
	.campl-theme-5 .campl-menu-btn-arrow{background: #772801 }
	.js .campl-theme-5 .campl-menu-btn-arrow a:focus, 
	.js .campl-theme-5 .campl-menu-btn-arrow a:hover{background-color:#772801;}
	.campl-theme-5 .campl-menu-indicator{background-color:#ab3901;}

	.js .campl-theme-5 .campl-local-navigation-container li:first-child a{border-top:1px solid #772801;}
	
	.js .campl-theme-5 .campl-local-navigation-container li.campl-back-link a,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-sub li.campl-back-link a,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a {border-bottom:1px solid #ab3901; background-color: #772801;}
	
	.js .campl-theme-5 .campl-local-navigation-container li.campl-back-link a:focus,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-back-link a:hover,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-5 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:hover{background-color:#ab3901;}
	
	/*theme 6*/
	.js .campl-theme-6 .campl-local-navigation a{background:#851735;border-right:0;border-top:0}
	.campl-theme-6 .campl-local-navigation li a{border-bottom:1px solid #a51137;}
	.js .campl-theme-6 .campl-local-navigation li li a,
	.js .campl-theme-6 .campl-local-navigation li.campl-sub li.campl-sub a{background-color:#851735;}
	
	.js .campl-theme-6 .campl-local-navigation li.campl-title a,
	.js .campl-theme-6 .campl-local-navigation li.campl-sub li.campl-title a,
	.js .campl-theme-6 .campl-local-navigation li.campl-sub li.campl-sub li.campl-title a,
	.js .campl-theme-6 .campl-local-navigation a:focus,
	.js .campl-theme-6 .campl-local-navigation a:hover,
	.js .campl-theme-6 .campl-local-navigation a:active{background-color:#a51137;}
	
	.js .campl-theme-6 .campl-local-navigation li.campl-sub a:focus, 
	.js .campl-theme-6 .campl-local-navigation li.campl-sub a:hover{background-color: #a51137}
	.js .campl-theme-6 .campl-local-navigation li.campl-sub li a:focus, 
	.js .campl-theme-6 .campl-local-navigation li.campl-sub li a:hover{background-color: #a51137;}

	.js .campl-theme-6 .campl-menu-btn a{border:0;background:#a51137;}
	
	.campl-theme-6 .campl-menu-btn-arrow{background: #6c112a }
	.js .campl-theme-6 .campl-menu-btn-arrow a:focus, 
	.js .campl-theme-6 .campl-menu-btn-arrow a:hover{background-color:#6c112a;}
	.campl-theme-6 .campl-menu-indicator{background-color:#a51137;}

	.js .campl-theme-6 .campl-local-navigation-container li:first-child a{border-top:1px solid #6c112a;}
	
	.js .campl-theme-6 .campl-local-navigation-container li.campl-back-link a,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-sub li.campl-back-link a,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a {border-bottom:1px solid #a51137; background-color: #6c112a;}
	
	.js .campl-theme-6 .campl-local-navigation-container li.campl-back-link a:focus,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-back-link a:hover,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-6 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:hover{background-color:#a51137;}

	/*theme 7*/
	.js .campl-theme-7 .campl-local-navigation a{background:#404040;border-right:0;border-top:0}
	.campl-theme-7 .campl-local-navigation li a{border-bottom:1px solid #5F5F5F;}
	.js .campl-theme-7 .campl-local-navigation li li a,
	.js .campl-theme-7 .campl-local-navigation li.campl-sub li.campl-sub a{background-color:#404040;}
	
	.js .campl-theme-7 .campl-local-navigation li.campl-title a,
	.js .campl-theme-7 .campl-local-navigation li.campl-sub li.campl-title a,
	.js .campl-theme-7 .campl-local-navigation li.campl-sub li.campl-sub li.campl-title a,
	.js .campl-theme-7 .campl-local-navigation a:focus,
	.js .campl-theme-7 .campl-local-navigation a:hover,
	.js .campl-theme-7 .campl-local-navigation a:active{background-color:#5F5F5F;}
	
	.js .campl-theme-7 .campl-local-navigation li.campl-sub a:focus, 
	.js .campl-theme-7 .campl-local-navigation li.campl-sub a:hover{background-color: #5F5F5F}
	.js .campl-theme-7 .campl-local-navigation li.campl-sub li a:focus, 
	.js .campl-theme-7 .campl-local-navigation li.campl-sub li a:hover{background-color: #5F5F5F;}

	.js .campl-theme-7 .campl-menu-btn a{border:0;background:#5F5F5F;}
	
	.campl-theme-7 .campl-menu-btn-arrow{background: #2F2F2F }
	.js .campl-theme-7 .campl-menu-btn-arrow a:focus, 
	.js .campl-theme-7 .campl-menu-btn-arrow a:hover{background-color:#2F2F2F;}
	.campl-theme-7 .campl-menu-indicator{background-color:#5F5F5F;}

	.js .campl-theme-7 .campl-local-navigation-container li:first-child a{border-top:1px solid #2F2F2F;}
	
	.js .campl-theme-7 .campl-local-navigation-container li.campl-back-link a,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-sub li.campl-back-link a,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a {border-bottom:1px solid #5F5F5F; background-color: #2F2F2F;}
	
	.js .campl-theme-7 .campl-local-navigation-container li.campl-back-link a:focus,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-back-link a:hover,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:hover,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:focus,
	.js .campl-theme-7 .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:hover{background-color:#5F5F5F;}
}

/* generic colours */
.campl-global-header{background:#000000}
.campl-global-footer{background:#171717}
.campl-global-navigation-drawer{background:#171717; color:#cecece}
.campl-global-navigation-outer{border: 1px solid #323232;border-width:0 1px 0 1px}
.campl-global-navigation-container {border-right: 1px solid #323232;}
.campl-global-navigation-container.last{border-right: 0;}

.campl-theme-1 .campl-homepage-content h2, .campl-theme-2 .campl-homepage-content h2, .campl-theme-3 .campl-homepage-content h2,
.campl-theme-4 .campl-homepage-content h2, .campl-theme-5 .campl-homepage-content h2, .campl-theme-6 .campl-homepage-content h2{color:#171717}

.campl-global-navigation-secondary-with-children, .campl-global-navigation-container li.last, .campl-global-navigation-secondary-with-children li{border-bottom:0}
.campl-global-navigation-container li, .campl-global-navigation-header-container li{border-bottom: 1px solid #323232}
.campl-global-navigation-tertiary li, .campl-global-navigation-secondary-with-children li{border-bottom:0}
.campl-recommended-results{background:#f0f8ff}

.campl-global-footer{color:#fff}
.campl-content{background:#fff}
.campl-secondary-content{background:#f3f3f3}

.campl-homepage-carousel .campl-carousel-control-btn, .campl-paging-btn{background:#171717}
.campl-homepage-carousel .campl-carousel-control-btn:hover{background:#000}
.campl-paging-btn:focus, .campl-paging-btn:hover{background:#454545}

.campl-inpage-search-container{background:#e4e4e4}
.campl-login-form{background:#f2f2f2}

.campl-highlight-date{color:#fff}
.campl-promo-teaser{background:#fff}

.campl-vertical-breadcrumb-navigation .campl-selected{color:#171717}

tbody th, td{background:#fff;color:#222}
/*reset background colour of themed tables with th in tbody*/
.campl-theme-1 tbody th, .campl-theme-2 tbody th, .campl-theme-3 tbody th, .campl-theme-4 tbody th, .campl-theme-5 tbody th, .campl-theme-6 tbody th{background:#fff;color:#222;border-bottom:0}
table th{border-bottom:#e4e4e4 1px solid}
.campl-table tbody tr:hover td, .campl-table tbody tr:hover th, 
.campl-theme-1 .campl-table tbody tr:hover td, .campl-theme-1 .campl-table tbody tr:hover th, 
.campl-theme-2 .campl-table tbody tr:hover td, .campl-theme-2 .campl-table tbody tr:hover th, 
.campl-theme-3 .campl-table tbody tr:hover td, .campl-theme-3 .campl-table tbody tr:hover th, 
.campl-theme-4 .campl-table tbody tr:hover td, .campl-theme-4 .campl-table tbody tr:hover th, 
.campl-theme-5 .campl-table tbody tr:hover td, .campl-theme-5 .campl-table tbody tr:hover th, 
.campl-theme-6 .campl-table tbody tr:hover td, .campl-theme-6 .campl-table tbody tr:hover th{background-color: #e4e4e4;}
.campl-vertical-stacking-table th{border-left:#e4e4e4 1px solid}

.campl-tertiary-navigation{border:1px solid #e4e4e4;border-width:1px 1px 0 1px }

/* Notifications */
.campl-success-panel{border-color:#55a51c}
.campl-success-panel .campl-notifications-icon{color:#55a51c}
.campl-warning-panel{border-color:#ff0000}
.campl-warning-panel .campl-notifications-icon{color:#ff0000}
.campl-alert-panel{border-color:#ea7125}
.campl-alert-panel .campl-notifications-icon{color:#ea7125}
.campl-information-panel{border-color:#6b2c91}
.campl-information-panel .campl-notifications-icon{color:#6b2c91}


/* 10.0 CSS3 ANIMATIONS ----------------------------------------------------------------------------------------------*/

/* 11.0 FOUC ----------------------------------------------------------------------------------------------*/

.js #global-header-controls{display:none}
.js .campl-carousel li{display:none}
.js .campl-secondary-content{display:none}

/* 12.0 PRINT STYLES ------------------------------------------------------------------------------------------------*/

@media print {
	
	.lt-ie9 .campl-row, .lt-ie8 .campl-row{min-width:auto}
	body{ background: transparent !important;}
	* { background: transparent !important; color: black !important; box-shadow:none !important; text-shadow: none !important; filter:none !important; -ms-filter: none !important; } /* Black prints faster: h5bp.com/s */
	#content a, #content  a:visited { text-decoration: underline; }
	#content a[href]:after { content: " (" attr(href) ")"; }
	
	.Zebra_DatePicker a[href]:after, .campl-carousel a[href]:after { display:none!important}
	abbr[title]:after { content: " (" attr(title) ")"; }
	#content .ir a:after, #content a[href^="javascript:"]:after, #content a[href^="#"]:after { content: ""; }  /* Don't show links for images, or javascript/internal links */
	pre{ border: 1px solid #999; page-break-inside: avoid; }
	thead { display: table-header-group; } /* h5bp.com/t */
	tr, img { page-break-inside: avoid; }
	img { max-width: 100% !important; }
	@page { margin: 0.5cm; }
	p, h2, h3 { orphans: 3; widows: 3; }
	h2, h3 { page-break-after: avoid; }	
	
	/* replace calendar backgrounds */
	.Zebra_DatePicker{width:300px}
	.Zebra_DatePicker .dp_daypicker td{background:#e1e1e1!important;}
	.Zebra_DatePicker td.dp_not_in_month{ background: #edeaea!important; color: #b4b3b3!important;}
	.Zebra_DatePicker td.dp_selected{ background: #222!important; color: #FFF !important }
	.Zebra_DatePicker td.dp_current {background:#888888!important; color: #fff!important }
	.Zebra_DatePicker td.dp_disabled_current{ background: #edeaea!important; color: #b4b3b3!important;}
	.Zebra_DatePicker td.dp_disabled, .Zebra_DatePicker td.dp_weekend_disabled{background: #edeaea!important; color: #b4b3b3!important;}
	.Zebra_DatePicker td.dp_hover{ background: #222!important; color: #FFF!important }
	.campl-event-indicator{background:#f3f3f3!important}

	/*stack all columns vertically to make it easier to read content*/
	[class*="campl-column"], .campl-column{
	  float: none;
	  display: block;
	  width: auto;
	  margin-left: 0;
	}
	
	/* remove recessed margin issues and add mobile container classes to reduce visual padding*/
	.campl-wrap{width:100%}
	.campl-search-container{padding:20px;}
	.campl-recessed-secondary-content {margin-top:0}
	.campl-spacing-column,  .campl-sub-section-title{display:none}
	.campl-homepage-logo-header{display:none}


	/* remove residual styling from tables and show by default */
	table, .campl-table-bordered table, .campl-table-bordered th, .campl-table-bordered td, .campl-table-bordered tr {border-color:#E4E4E4!important}
	caption{ border: 1px solid #E4E4E4!important; page-break-inside: avoid;}
	.campl-responsive-table table{display:block!important}

	/* style elements back for legibility, add a bg back into the homepage teaser campl-topic to make legible */
	.campl-topic{background:#fff!important}
	.campl-primary-cta{color:#fff!important}

	/* add background to transparent graphics to make legible */
	.campl-co-branding-logo{padding:5px;background:#000!important;display:block;float:none}
	.campl-main-logo{padding:5px;background:#000 !important;display:block;float:none}

	/*hide unwanted site furniture and nav elements*/
	.campl-global-header{display:none!important}
	.campl-breadcrumb{display:none!important}
	.campl-global-navigation-drawer{display:none!important}
	.campl-local-navigation{display:none!important}
	.campl-tertiary-navigation{display:none!important}
	.campl-mobile-parent{display:none!important}
	.campl-nav-tabs{display:none!important}
	.campl-nav-pills{display:none!important}
	.campl-inpage-search-container{display:none!important}
	.campl-search-container{display:none!important}
	.campl-list-container{display:none!important}
	.campl-local-footer{display:none!important}
	.campl-global-footer{display:none!important}
	.campl-load-more-btn{display:none!important}
	.campl-pagination{display:none!important}
	.campl-paging{display:none!important}
	.campl-notifications-panel{display:none!important}
	.campl-open-responsive-table-link{display:none!important}
	.campl-homepage-header{display:none!important}
	.campl-homepage-logo-header{display:none!important}

	.campl-slide-caption{position:static}
	.campl-focus-teaser-txt{float:none;clear:both}
	.campl-carousel .campl-slide{display:block}
}


/* 12.0 Z-INDEXES ------------------------------------------------------------------------------------------------
 * 
 * z-index:0	campl-page-sub-title
 * z-index:1
 * z-index:2	
 * z-index:3	
 * z-index:4	global navigation drawer
 * z-index:5	campl-search-drawer
 * z-index:6	campl-quicklinks-list
 * z-index:7	campl-global-navigation link hover state/selected tab 
 * z-index:8	
 * z-index:9	campl-vertical-breadcrumb-indicator
 * z-index:10 	
 * z-index:11	tertiary navigation
 * z-index:12	campl-topic on homepage teaser
 * z-index:13	campl-local-navigation
 * z-index:14 	campl-local-navigation li ul - hover menu
 * z-index:15 	global header
 * z-index:16 	
*/

