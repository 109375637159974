.cudl-editor-confirmation {
    width: 350px;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    z-index: 9000;
    left: 0;
    right: 0;
    top: 200px;
    color: #000;
}

.cudl-editor-confirmation input {
    width: 200px;
    height:30px;
}

.cudl-editor-confirmation .close {
    margin-top: -15px;
    margin-right: -10px;
}
