/* 3rd party CSS libs */

/* homepage style */
.carousel-panel {
    display: none;
}

#header-search {
    height: 32px;
}



@media screen and (min-width: 1177px) {
    .carousel-panel {
        background: url(../assets/grey-85b9bba348aada67c99aa39077b1d767.png) repeat scroll 0px 0px
            transparent;
        height: 374px;
        right: 0px;
        padding: 10px;
        position: absolute;
        float: right;
        top: 0px;
        width: 294px;
        color: #fff;
        text-align: left;
        display: inline-block;
    }
    .carousel-panel a {
        text-decoration: underline;
    }
    .campl-carousel-pagination {
        right: 108px;
    }
    .campl-slide-caption {
        right: 0px;
    }
}

#header-search input[type="text"] {
    height: 32px;
}
/* end of homepage style */
#noscript-warning {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2000;
    vertical-align: middle;
}

#noscript-warning p {
    text-align: center;
    vertical-align: middle;
}

.contibutors li {
    padding: 5px;
}

.terms li {
    padding: 5px;
}

.container {
    background-color: #FFFFFF;
}

.library-logo {
    margin: 20px 0;

    background-image: url(../assets/ul-600-logo-landscape-516c7fa78809e16a1d6bdc1737a61e87.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;

    display: block;
    height: 80px;
}
@media (max-width: 767px) {
    .library-logo {
        background-position: center;
        margin: 20px 10px;
    }

    .campl-global-header {
        height:100px;
    }
}

.content {
    background-color: #FFFFFF;
}

.cookienotice {
    color: #fff;
    background: #171717;
}

/* Text for downtime message on main page */
.warning {
    border-color: #ffaaaa;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    margin-top: 24px;
    background-color: #ffeeee;
}

.footer a:link, .footer a:visited, .footer a:active {
    color: #FFFFFF;
    text-decoration: underline;
}

.featuredcollection img {
    opacity: 1.0;
    position: relative;
    margin-bottom: 58px;
    border-style: solid;
    border-top-width: 4px;
    border-left-width: 4px;
    border-right-width: 4px;
    border-bottom-width: 0px;
    border-color: #fff;
}

.featuredcollection:hover img {
    border-color: #333;
}

.featuredcollection:hover .featuredcollectionlabel {
    border-color: #333;
}

.featuredcollection .featuredcollectionlabel {
    z-index: 10;
    width: 118px;
    display: block;
    padding: 5px;
    position: absolute;
    color: #eee;
    bottom: 8px;
    height: 40px;
    left: 0px;
    background: #333;
    float: left;
    border-style: solid;
    border-top-width: 0px;
    border-left-width: 4px;
    border-right-width: 4px;
    border-bottom-width: 4px;
    border-color: #fff;
}

.collectionImage {
    margin-left: 0px;
    margin-right: 14px;
    float: left;
}

.featuredItem-text {
    width: 200px;
}

.featuredItem-text a {
    text-decoration: none;
}

.featuredItem {
    position: relative;
    margin-left: 18px;
}

.featuredItem img {
    border-style: solid;
    border-width: 1px;
    border-color: #333;
}

/** related item - used on essay page */
.relatedItem {
    width: 192px;
    margin-bottom: 8px;
    border-width: 2px;
    border-color: #eee;
    border-style: solid;
    padding: 4px;
}

.relatedItem a {
    text-decoration: none;
    position: relative;
}

.relatedItem img {
    border-style: solid;
    border-width: 1px;
    border-color: #333;
}

.foundations-link-faith, .foundations-link-faith a {
    color: #8f7722;
    position: absolute;
    right: 4px;
    bottom: 0px;
    text-align: right;
    width: 200px;
    text-decoration: none;
}

.foundations-link-science, .foundations-link-science a {
    color: #324088;
    position: absolute;
    right: 4px;
    bottom: 0px;
    text-align: right;
    width: 200px;
    text-decoration: none;
}

.right-aligned-language {
    font-style: normal;
    margin-left: auto;
    margin-right: 0px;
    text-align: right;
}

#document-about-radio-link {
    font-weight: bold;
    color: #333;
}

#document-about-radio-link a {
    font-weight: bold;
    color: #333;
}

#document-about-radio-icon-div {
    border: 3px;
    border-color: #000;
    font-weight: bold;
}

#document-about-exhibition-link {
    font-weight: bold;
    color: #333;
}

#document-about-exhibition-link a {
    font-weight: bold;
    color: #333;
}

#document-about-exhibition-icon-div {
    border: 3px;
    border-color: #000;
    font-weight: bold;
}

/* Longitude parent collection page */
.parent_featured_item_image img {
    margin: auto;
    text-decoration: none;
    border: none;
    outline: none;
}

.parent_featured_item_image_box {
    width: 180px;
    height: 180px;
    background: #000000;
    padding: 5px;
    border-style: solid;
    border-width: 3px;
    border-color: #333;
}

.parent_featured_item_image_box:hover {
    border-color: #999;
}

.parent_featured_item_image {
    display: table-cell;
    vertical-align: middle;
    width: 180px;
    height: 180px;
}

/* end of longitude parent collection page */
.helpBanner {
    margin-top: 20px;
}

.helpBanner img {
    display: inline;
    margin-bottom: 0px;
    margin-top: 6px;
    height: 10px;
}

.helpBanner a {
    align: left;
    font-size: 1em;
    text-decoration: none;
    font-weight: bold;
}

blockquote {
    border-left: 0px;
    margin-right: 20px;
}

.cam-quote-mark {
    float: left;
    margin-right: 20px;
}

.collectionOrganisationalImage {
    margin-bottom: 15px;
    float: none;
}

.collections_carousel {
    position: relative;
    display: block;
}

.grid_carousel ol {
    width: 900px;
}

.grid_carousel ol li {
    float: left;
    width: 550px;;
}

.matrix_carousel ol {
    width: 800px;
    margin-left: 70px;
    margin-bottom: 18px;
}

.matrix_carousel ol li {
    float: left;
    width: 180px;;
}

.collections_carousel_item {
    width: 100%;
    font-style: normal;
    position: relative;
    display: block;
    position: relative;
    min-height: 190px;
}

#subcollection-breadcrumb {
    color: #000;
}

#subcollection-breadcrumb li {
    padding: 0px 9px 0px 0px;
    background: #fff;
}

ol#collections_carousel li {
    list-style: none outside none;
}

ol#search_carousel li {
    list-style: none outside none;
}

.altsearchlink input[type="submit"] {
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    float: right;
    margin-right: 5px;
    font-color: #ff0000;
}

.search-facets {
    list-style-type: none;
}

.search-facet-selected {
    padding: 3px;
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
}

.search-close {
    cursor: pointer;
    height: 19px;
    width: 19px;
    z-index: 3;
}

/* for virtual collections */
.virtual_collections_carousel {
    position: relative;
    display: block;
}

ol#virtual_collections_carousel li {
    list-style: none outside none;
    float: none;
    width: 100%;
}

.virtual_collections_carousel_item {
    width: 100%;
    font-style: normal;
    position: relative;
    display: block;
    position: relative;
    min-height: 190px;
    max-width: 500px;
    float:left;
}

.virtual_collections_carousel_image_box {
    width: 190px;
    height: 190px;
    background: #000000;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #333;
   float:left;
   margin-right:20px;
}

.virtual_collections_carousel_image {
   display: table-cell;
   vertical-align: middle;
   width: 180px;
   height: 180px;
   text-align: center;
}

.virtual_collections_carousel_image img {
   margin: auto;
   text-decoration: none;
   border: none;
   outline: none;
   max-height: 180px;
   max-width: 180px;
}

.virtual-collections-items {
   float:left;
   padding-top:18px;
}

.virtual_collections_carousel_text {
   float:left;
   margin-left: 10px;
   margin-left: 10px;
   margin-right:10px;
   margin-bottom:10px;
}

.virtual_collection_summary_images {
   width:460px;
   height:500px;
   float:none;
}

@media screen and (max-width: 767px) {
   .virtual_collection_summary_images {
       display:none;
    }
    .virtual_collection_summary_image {
       display:none;
    }
}

.virtual_collection_summary_image {
   position: relative;
   float: left;
   height: 250px;
   width: 230px;
}

.virtual_collection_summary {
   position: relative;
   margin-bottom: 18px;
   float:none;
   background-color:#fff;
}

.virtual_collection_sponsor {
   float:left;
}

.virtual_collection_items {
   position: relative;
   float:left;
}

.virtual_collection_textgroup {
   padding-right: 20px;
}

/* Note the max height and width are set in jsp also */
.collections_carousel_image img {
    margin: auto;
    text-decoration: none;
    border: none;
    outline: none;
    max-height: 180px;
    max-width: 180px;
}

.collections_carousel_image_box {
    float:right;
    width: 190px;
    height: 190px;
    background: #000000;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #333;
}

.collections_carousel_image {
    display: table-cell;
    vertical-align: middle;
    width: 180px;
    height: 180px;
    text-align: center;
}

@media screen and (max-width: 320px) {
    .collections_carousel_image_box {
        float: none;
    }
    .collections_carousel_item {
        margin-bottom:20px;
        margin-left:20px;
        margin-right:20px;
    }
}

.word-wrap-200 {
    word-wrap: break-word;
    width: 200px;
    padding:5px;
}
@media screen and (max-width: 767px) {
    .word-wrap-200 {
        width: 80%;
    }
}

.morelink a:link, .morelink a:visited, .morelink a:active {
    color: #3a6c8f;
}

.lesslink a:link, .morelink a:visited, .morelink a:active {
    color: #3a6c8f;
}

.faq ol {
    list-style: circle outside none;
}

.faq ol li {
    margin: 5px;
}

.terms ol {
    list-style: circle outside none;
}

.terms ol li {
    margin-top: -5px;
    margin-bottom: -5px;
    margin-left: 15px;
}

/* credit text for images, small */
.image-credit, .image-credit a {
    font-size: 0.9em;
    color: #999999;
    vertical-align: top;
    text-decoration: none;
    border: 0px;
    padding: 0px;
}

.image-credit-darwin, .image-credit-darwin a {
    font-size: 0.9em;
    color: #999999;
    text-decoration: none;
    margin-left: 25px;
}

.image-credit-islamic, .image-credit-islamic a {
    font-size: 0.9em;
    color: #999999;
    text-decoration: none;
    margin-right: 10px;
    float: right;
    margin-bottom: 10px;
}

.search-result-snippet {
    font-size: 0.8em;
    color: #999999;
}

.search-result-imgcontainer {
    width: 180px;
    height: 180px;
    background: #000000;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #333;
    display: table-cell;
    vertical-align: middle;
}

.search_results .page_navigation {
    margin: 0px;
    float: right;
}

.clear {
    clear: both;
}

/* For pagination */
div.pagination {
    text-align: center;
}

div.pagination a:hover, div.pagination span.current {
    background-color: #F2F2F2;
    color: #000;
}

div.pagination a {
    font-style: normal;
    background-color: #fff;
    border-color: #D6D6D6;
    border-style: solid;
    border-width: 1px;
    color: #000;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    margin: 0px 2px;
    padding: 4px 7px;
    text-align: center;
    text-decoration: none;
}

div.pagination span.current {
    font-style: normal;
    background-color: #F2F2F2;
    border-color: #D6D6D6;
    border-style: solid;
    border-width: 1px;
    color: #000;
    font-size: 11px;
    font-weight: 700;
    line-height: 12px;
    margin: 0px 2px;
    padding: 4px 7px;
    text-align: center;
    text-decoration: none;
}

div.pagination a.next, div.pagination span.disabled, div.pagination a.last,
    div.pagination a.first, div.pagination a.prev {
    font-style: normal;
    background-color: #000;
    border-color: #000;
    border-style: solid;
    border-width: 1px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    margin: 0px 2px;
    padding: 4px 7px;
    text-align: center;
    text-decoration: none;
}

div.pagination a.next:hover, div.pagination a.last:hover, div.pagination a.first:hover,
    div.pagination a.prev:hover {
    background-color: #454545
}

/* search on bar */
#navPrimary form {
    float: right;
    height: 36px;
    position: relative;
}

#navPrimary #search {
    background: none repeat scroll 0 0 white;
    color: #666666;
    font-family: Georgia, 'Times New Roman', serif;
    font-size: 13px;
    font-style: italic;
    padding: 5px 5px;
    position: absolute;
    top: 5px;
    right: 4px;
    width: 230px;
    z-index: 2;
}

#navPrimary #submit {
    background: url(../assets/sprite-5b41b1b4bfafc4bb29db2a1bb9d37de5.png) no-repeat scroll -768px -17px
        transparent;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    overflow: hidden;
    position: absolute;
    right: 10px;
    text-indent: -9999px;
    top: 9px;
    width: 16px;
    z-index: 3;
}

#navPrimary input {
    margin-right: 2px;
}

/* search on other page */
.search #search {
    background: none repeat scroll 0 0 white;
    color: #666666;
    font-family: Georgia, 'Times New Roman', serif;
    font-size: 13px;
    font-style: italic;
    padding: 5px 5px;
    position: absolute;
    top: 5px;
    right: 4px;
    width: 230px;
    z-index: 2;
}

.search #submit {
    background: url(../assets/sprite-5b41b1b4bfafc4bb29db2a1bb9d37de5.png) no-repeat scroll -768px -17px
        transparent;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    overflow: hidden;
    position: absolute;
    right: 10px;
    text-indent: -9999px;
    top: 9px;
    width: 16px;
    z-index: 3;
}

.search input {
    margin-right: 2px;
}

/* Search box on search results page */
.searchform form {
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

/* search on search results page */
.searchform .search {
    background: none repeat scroll 0 0 white;
    color: #666666;
    padding: 5px 5px;
    top: 0px;
    margin-right: 0px;
    width: 230px;
    height: 32px;
}

.searchform .campl-search-submit {
    margin-bottom: 1px;
    margin-left: -5px;
}

/* search style text - for examples on search results page */
.searchexample .search {
    background: none repeat scroll 0 0 white;
    color: #666666;
    font-family: Georgia, 'Times New Roman', serif;
    font-size: 13px;
    font-style: italic;
    padding: 3px 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #ccc;
    font-weight: bold;
}

.searchexample {
    line-height: 25px;
}

.searchform #submit {
    background: url(../assets/sprite-5b41b1b4bfafc4bb29db2a1bb9d37de5.png) no-repeat scroll -768px -17px
        transparent;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    overflow: hidden;
    position: absolute;
    right: 5px;
    text-indent: -9999px;
    top: 5px;
    width: 16px;
    z-index: 3;
}

/* advancd search query page */
.advancedsearchform {
    position: relative;
}

.advancedsearchform input {
    background: #fff;
    padding: 5px 5px;
    margin: 5px;
    color: #666666;
    font-family: Georgia, 'Times New Roman', serif;
    z-index: 2;
    border-style: solid;
    border-width: 1px;
    border-color: #999;
    font-weight: bold;
    height: 28px;
}

.advancedsearchform label {
    margin-top: 8px;
    margin-left: auto;
    margin-right: 0px;
    display: inline-block;
}

.advancedsearchform .radiobutton {
    background-color: transparent;
    border-width: 0px;
}

/* longitude collection page */
.nmm_icon {
    margin-bottom: 0px;
    display: inline;
}

.longitudefeaturedItemsBox {
    height: 546px;
    width: 666px;
    background: none repeat scroll 0 0 #333333;
    overflow: hidden;
    padding: 18px 16px 9px;
    margin-bottom: 18px;
}

/* essay display page */
.essay_image {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}

.longitudestatistics {
    margin-bottom: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: #ccc;
    color: #666;
    margin-bottom: 15px;
}

.longitudestatistics a {
    color: #666;
}

.longitudestatistics ul {
    margin-bottom: 0px;
}

.longitudestatistics ol {
    margin-bottom: 0px;
    padding-left: 25px;
}

.longitudestatisticstitle {
    text-align: left;
    margin-bottom: 0px;
    padding-left: 8px;
    color: #fff;
    background-color: #ccc;
    border-color: #ccc;
    font-weight: bold;
    margin-bottom: 0px;
}

.longitudefeaturedcollection {
    margin-right: 15px;
    margin-left: 14px;
}

/* longitude sub collections */
.longitudefeaturedcollection img {
    opacity: 1.0;
    position: relative;
    margin-bottom: 58px;
    border-style: solid;
    border-top-width: 4px;
    border-left-width: 4px;
    border-right-width: 4px;
    border-bottom-width: 0px;
    border-color: #fff;
}

.longitudefeaturedcollection:hover img {
    border-color: #333;
}

.longitudefeaturedcollection .featuredcollectionlabel {
    z-index: 10;
    width: 118px;
    display: block;
    padding: 5px;
    position: absolute;
    color: #eee;
    bottom: 8px;
    height: 60px;
    left: 0px;
    background: #333;
    float: left;
    border-style: solid;
    border-top-width: 0px;
    border-left-width: 4px;
    border-right-width: 4px;
    border-bottom-width: 4px;
    border-color: #fff;
}

.longitudefeaturedcollection:hover .featuredcollectionlabel {
    border-color: #333;
}

/* longitude disabled collection */
.longitudefeaturedcollectiondisabled:hover .featuredcollectionlabel {
    border-color: #FFF;
}

.longitudefeaturedcollectiondisabled {
    opacity: 0.5;
}

.longitudefeaturedcollectiondisabled:hover img {
    border-color: #FFF;
}

.longitudefeatureditem {
    width: 205px;
    display: inline;
    float: left;
    margin-left: 8px;
    margin-right: 8px;
    position: relative;
    color: #ffffff;
}

.longitudeessaymetadata {
    background: none repeat scroll 0 0 #DBE8F1;
    line-height: 200%;
    padding: 15px;
}

.news_items img {
    float: left;
    margin-right: 20px;
}

.funder_item {
    margin-bottom: 20px;
    margin-top: 20px;
}

.campl-login-form {
    float:none;
    margin: 0 auto;
}

.btn-raven{color:#fff;background-color:#7f7f7f;border-color:rgba(0,0,0,0.2)}.btn-raven:hover,.btn-raven:focus,.btn-raven:active,.btn-raven.active,.open>.dropdown-toggle.btn-raven{color:#fff;background-color:#4d4d4d;border-color:rgba(0,0,0,0.2)}
.btn-raven:active,.btn-raven.active,.open>.dropdown-toggle.btn-raven{background-image:none}
.btn-raven.disabled,.btn-raven[disabled],fieldset[disabled] .btn-raven,.btn-raven.disabled:hover,.btn-raven[disabled]:hover,fieldset[disabled] .btn-raven:hover,.btn-raven.disabled:focus,.btn-raven[disabled]:focus,fieldset[disabled] .btn-raven:focus,.btn-raven.disabled:active,.btn-raven[disabled]:active,fieldset[disabled] .btn-raven:active,.btn-raven.disabled.active,.btn-raven[disabled].active,fieldset[disabled] .btn-raven.active{background-color:#007bb6;border-color:rgba(0,0,0,0.2)}
.btn-raven .badge{color:#007bb6;background-color:#fff}
.img-raven { margin-top:3px; }


.btn-google, .btn-facebook, .btn-twitter, .btn-linkedin, .btn-raven {
    height: 37px;
    color: #FFF;
}

#openid_identifier {
    height: 30px;
}

#login_error {
    color: #f00;
    font-weight: bold;
    margin-bottom: 20px;
}

.bookmark-removelink {
    float: right;
    margin-right: 10px;
}

.campl-btn.campl-primary-cta.campl-btn-darkbg {
    border: 1px solid #545454;
}
