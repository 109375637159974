/* Manchester Digital Library Styles */

/* Change Project Light styles to Purple */
.campl-page-header,
.campl-local-footer{background:#ececed;}
.campl-local-footer, .campl-local-footer h3 a{color: #8434ad;}
.campl-page-sub-title,
.campl-banner-content{background:#6b2c91}

.campl-carousel-control-btn{background:#A268C1}
.campl-carousel-control-btn:hover{background:#6b2c91}

.campl-homepage-carousel .campl-slide{background:#A268C1;color:#fff}
.campl-partnership-branding{border-color:#A268C1}

.campl-highlight-date{background:#A268C1}
.campl-focus-teaser{background:#8434AD;}
.campl-focus-link{background-color:#6b2c91}
.campl-main-content h1,
.campl-main-content h2 {color:#8434AD; }

table,
.campl-table-bordered {border-bottom:2px solid #A268C1;}
th{background:#A268C1;color:#fff}
th.campl-alt{background:#fff;color:#A268C1}
.campl-table-striped tbody tr:nth-child(odd) td,
.campl-table-striped tbody tr:nth-child(odd) th { background-color: #DED0E9;}
.campl-table-bordered th{border-left: 1px solid #DED0E9;}


/* local navigation */
.campl-local-navigation {background:#6b2c91;border-bottom:1px solid #8434AD;border-top:1px solid #8434AD;}
.campl-local-navigation a{background:#6b2c91;border-right:1px solid #8434AD;border-bottom:1px solid #8434AD;border-top:1px solid #8434AD;}
.campl-local-navigation a:focus,
.campl-local-navigation a:hover,
.campl-local-navigation a:active{background-color:#8434AD;}
.campl-local-navigation a.campl-selected{background:#A268C1}
.campl-local-navigation li.campl-hover a{background-color:#2c0142;border-bottom:0}
.campl-local-navigation li.campl-sub li a{background-color: #2c0142;border-right:0; }
.campl-local-navigation li.campl-sub li li a{border-bottom:1px solid #6b2c91;}
.campl-local-navigation li.campl-sub li:last-child a {border-bottom:0;}

.js .campl-local-navigation li.campl-sub>a:focus,
.js .campl-local-navigation li.campl-sub>a:hover,
.js .campl-local-navigation li.campl-sub>a:active{background-color:#6b2c91;}

.js .campl-local-navigation li.campl-sub a:focus,
.js .campl-local-navigation li.campl-sub a:hover{background-color: #2c0142}
.js .campl-local-navigation li.campl-sub li a:focus,
.js .campl-local-navigation li.campl-sub li a:hover{background-color: #6b2c91;}


/* Text colours */

.campl-page-header h1, .campl-page-title {
    color: #000;
}

.campl-breadcrumb, .campl-breadcrumb a {
    color: #000;
    text-decoration: none;
}

.campl-breadcrumb a:hover {
    text-decoration: underline;
}

.campl-global-header {
    background: #FFF;
}

.campl-global-footer {
    background: #1d1d1d;
}

.campl-page-sub-title h1, .campl-sub-title {
    color: #fff;
}

.campl-footer-navigation {
    background: inherit;
}

.campl-footer-navigation li {
    color:#000;
}

.campl-footer-navigation li a {
    color:#000;
}

.cookienotice {
    color: #fff;
    background: #171717;
}

/* Update the header padding */
.campl-page-title {
    margin-left: -20px;
}

.campl-breadcrumb {
    margin-left: -20px;
}

.campl-side-padding {
    padding: 0 20px;
}

/* Breadcrumb Icons */
.campl-breadcrumb .campl-home{background: url(../assets/icon-breadcrumb-home-dark-02f07c751690334ea4f3a9b69b84006b.png) 0 0 no-repeat;width:17px; height:17px;padding:0}
.campl-breadcrumb li{padding:0 2px 0 17px;background: url(../assets/bg-breadcrumb-link-dark-7d54c5b7815fdf8a4fa3bf35d5a47b06.png) 5px 50% no-repeat;white-space:nowrap;}

/* Always hide the project light menu - usually displayed on low resolutions. */
.js .campl-open-menu {
    display: none;
}

.campl-icon-search-btn {
    display: none;
}

/* Additional Manchester Footer Styles */
.manchesterInlineList {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'Open Sans',sans-serif;
    font-weight: 400;
}

.manchesterInlineList li {
    line-height: 2;
    display: inline-block;
    margin: 0;
    padding: 0 5px 0 0;
    font-size: 1.2em !important;
    color: #aaa;
    font-weight: 400;
}

.manchesterInlineList li a {
    display: inline-block;
    padding: 5px;
}
.manchesterfooterContainer li a {
    color: #aaa;
    text-decoration: none;
}
.manchesterfooterContainer li a:hover {
    text-decoration: underline;
}
.manchesterfootertwelvecol {
    width: 100%;
    float: left;
    line-height: 1.5;
    position: relative;
    padding: 10px 0px 10px 20px;
}

/* CDL logo styles */
.cdl-logo-div-parent {
    width: 100%;
    height:150px;
    position: relative;
}

.cdl-logo-div {
    position: absolute;
    bottom: 0;
    right: 0;
}

/* Additional Footer style */
.campl-footer-navigation a:hover {
    text-decoration: underline;
}
.campl-footer-navigation a {
    text-decoration: none;
}

/* Fonts */
.campl-page-sub-title h1, .campl-sub-title {
    font-family: 'Open Sans', sans-serif;
}

body {
    font-family: 'Open Sans', sans-serif;
}

.campl-btn, .campl-global-footer h3, .campl-global-navigation, .campl-global-navigation-container, .campl-global-navigation-header-container, .campl-listing-title, .campl-local-footer h3, .campl-teaser-title, .campl-vertical-breadcrumb, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', sans-serif;
}

.campl-branding-title, .campl-global-navigation-tertiary, .campl-local-navigation, .campl-topic, .campl-vertical-breadcrumb-navigation {
    font-family: 'Open Sans', sans-serif;
}

.campl-carousel-content p, .campl-highlight-date, .campl-homepage-content h2, .campl-light-heading, .campl-main-content-sub-column h2, .campl-page-header h1, .campl-page-sub-title h2, .campl-page-title, .campl-primary-cta, .campl-secondary-content h2, .campl-slide-caption-txt, .campl-sub-title, h1, legend {
    font-family: 'Open Sans', sans-serif;
}

button, html, input, select, textarea {
    font-family: 'Open Sans',sans-serif;
}

/* styles for menu opening navigation - ipad portrait and lower */
@media only screen and (max-width:767px) {

    .js .campl-local-navigation a {
        background: #6b2c91;
    }

    .campl-local-navigation li a {
        border-bottom: 1px solid #8434AD;
    }

    .js .campl-local-navigation li li a,
    .js .campl-local-navigation li.campl-sub li.campl-sub a {
        background-color: #6b2c91;
    }

    .js .campl-local-navigation li.campl-title a,
    .js .campl-local-navigation li.campl-sub li.campl-title a,
    .js .campl-local-navigation li.campl-sub li.campl-sub li.campl-title a,
    .js .campl-local-navigation a:focus,
    .js .campl-local-navigation a:hover,
    .js .campl-local-navigation a:active {
        background-color: #8434AD;
    }

    .js .campl-local-navigation li.campl-sub a:focus,
    .js .campl-local-navigation li.campl-sub a:hover {
        background-color: #8434AD;
    }

    .js .campl-local-navigation li.campl-sub li a:focus,
    .js .campl-local-navigation li.campl-sub li a:hover {
        background-color: #8434AD;
    }

    .js .campl-menu-btn a {
        border: 0;
        background: #8434AD;
    }

    .campl-menu-btn-arrow {
        background: #2c0142;
    }

    .js .campl-menu-btn-arrow a:focus,
    .js .campl-menu-btn-arrow a:hover {
        background-color: #2c0142;
    }

    .campl-menu-indicator {
        background-color: #8434AD;
    }

    .js .campl-local-navigation-container li:first-child a {
        border-top: 1px solid #2c0142;
    }

    .js .campl-local-navigation-container li.campl-back-link a,
    .js .campl-local-navigation-container li.campl-sub li.campl-back-link a,
    .js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a,
    .js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a {
        border-bottom: 1px solid #8434AD;
        background-color: #2c0142;
    }

    .js .campl-local-navigation-container li.campl-back-link a:focus,
    .js .campl-local-navigation-container li.campl-back-link a:hover,
    .js .campl-local-navigation-container li.campl-sub li.campl-back-link a:focus,
    .js .campl-local-navigation-container li.campl-sub li.campl-back-link a:hover,
    .js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:focus,
    .js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-back-link a:hover,
    .js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:focus,
    .js .campl-local-navigation-container li.campl-sub li.campl-sub li.campl-sub li.campl-back-link a:hover {
        background-color: #8434AD;
    }

    .campl-page-title {
        margin-left: 0;
    }

}
